import Divider from '../../../../components/divider/Divider'
import SvgCancel from '../../../../components/svg/logo/SvgCancel'
import { Backgrounds } from '../../../../shared/styles'
import { COLORS } from '../../../../theme/Colors'
import {
  formattingCurrencyWithDecimal,
  formattingDate,
  formattingNumber,
} from '../../../../utils/globalFunctions'
import RecordDetailsTable from './RecordDetailsTable'
import {
  CancelButtonWrapper,
  MarginLeftAutoSpace,
  RecordDetailsPopUpStyle,
  StyledSelectContainer,
} from './styles'

const AdminWindowRecordDetails = (props: any) => {
  const tableColumns = [
    {
      Header: 'Security Category',
      accessor: 'securityCategory',
    },
    {
      Header: 'CUSIP',
      accessor: 'cusip',
    },
    {
      Header: 'CUSIP Description',
      accessor: 'cusipDesc',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'Account Number',
      accessor: 'accountNumber',
    },
    {
      Header: 'Settle Date',
      accessor: (d: any) => {
        return formattingDate(d.settleDate)
      },
    },
    {
      Header: 'Quantity',
      accessor: (d: any) => {
        return d.quantity ? formattingNumber(d.quantity) : null
      },
    },

    {
      Header: 'Dollar',
      accessor: (d: any) => {
        if (d.dollar) {
          return formattingCurrencyWithDecimal(d.dollar)
        }
      },
    },
    {
      Header: 'Cent',
      accessor: (d: any) => {
        if (d.cent) {
          return formattingNumber(d.cent)
        }
      },
    },
    {
      Header: 'Sec Type',
      accessor: 'secType',
    },
    {
      Header: 'Delivery Broker',
      accessor: 'delvBroker',
    },
    {
      Header: 'Trade Date',
      accessor: (d: any) => {
        return formattingDate(d.tradeDate)
      },
    },
    {
      Header: 'CounterParty Type',
      accessor: 'counterpartyType',
    },
    {
      Header: 'Delivery date',
      accessor: (d: any) => {
        if (d.businessDays) return formattingDate(d.businessDays)
      },
    },
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Status',
      accessor: 'dtccStatus',
    },
  ]
  return (
    <RecordDetailsPopUpStyle>
      <StyledSelectContainer
        style={{
          padding: '15px 15px 0px 15px',
          fontWeight: '600',
          borderRadius: '8px 8px 0px 0px',
        }}
      >
        <>Admin Window Record Details</>
        <MarginLeftAutoSpace>
          <CancelButtonWrapper
            onClick={() => {
              props.setOpenRecordsDetails(false)
            }}
          >
            <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
          </CancelButtonWrapper>
        </MarginLeftAutoSpace>
        <Divider
          thickness={'1px'}
          horizontal={true}
          bgColor={Backgrounds.Gray110}
        />
      </StyledSelectContainer>

      <RecordDetailsTable
        columns={tableColumns}
        data={props.data}
        selectedRowId={props.selectedRowId}
        totalRecords={props.totalRecords}
        gotoFirstPage={props.gotoFirstPage}
      />
    </RecordDetailsPopUpStyle>
  )
}

export default AdminWindowRecordDetails
