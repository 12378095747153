/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react'
import SelectDropdown from '../../components/selectDropdown/SelectDropdown'

export const ActionExecutionButton: FC<any> = ({
  disableFunction,
  parentValue,
  label,
  tableId,
  currentRow,
  updateAction,
}) => {
  const onClick = () => {
    updateAction(tableId, currentRow)
  }
  return (
    <button
      disabled={disableFunction}
      style={{ cursor: 'pointer' }}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

export const AssetExecutionDropDown: FC<any> = ({
  parentValue,
  rowIndex,
  objectKey,
  setParentValue,
  defaultValue,
  options,
}) => {
  const [value, setValue] = useState(
    parentValue?.current[rowIndex]?.[objectKey]?.toString()
  )
  return (
    <SelectDropdown
      width="150px"
      defaultValue={defaultValue}
      borderRadius={'4px'}
      height="32px"
      options={options}
      onChange={(e) => {
        setValue(e);
        setParentValue((prevValue: any) => [
          ...prevValue.slice(0, rowIndex),
          { ...prevValue[rowIndex], [objectKey]: e },
          ...prevValue.slice(rowIndex + 1),
        ]);
      }}
    ></SelectDropdown>
  )
}
