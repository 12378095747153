/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StyledTableContainer, PopUpSideBar } from '../SettlementCommonStyles'
import Table from '../table/Table'
import { shortSalesDetailsColumns } from './shortSalesColumns'
import Comments from '../../../composites/comments/comments'
import {
  SettlementShortSalesSelector,
  ShortSalesTotalCountSelector,
  getBetaTradeReportComments,
  getSettlementShortSalesDetails,
  saveBetaTradeReportComment,
} from '../../../store/settlementShortSales/settlementShortSales'
import { TabListProps } from '../../../components/tab/TabListModels'
import { getAccessFlag } from '../../../utils/globalFunctions'

const TradeReportDetails = ({
  commonTabProps,
  setCommonTabProps,
}: TabListProps) => {
  const dispatch = useDispatch()
  const [tableCommmentsData, setTableCommmentsData] = useState<any>([])
  const commentViewReceive = useRef<HTMLInputElement>()
  const [toggleComments, SetToggleComments] = useState<boolean>(false)
  const [comment, setComment] = useState<string>('')
  const [selectedRow, setSelectedRow] = useState<any>([])
  const SettlementShortSaleData = useSelector(SettlementShortSalesSelector)
  const ShortSalesTotalCount = useSelector(ShortSalesTotalCountSelector)
  const [accessFlag, setAccessFlag] = useState<boolean>(false)

  useEffect(() => {
    setCommonTabProps &&
      setCommonTabProps({ ...commonTabProps, tabName: 'Details' })
  }, [])

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (
        commentViewReceive?.current &&
        !commentViewReceive?.current?.contains(event.target)
      ) {
        SetToggleComments(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside, {
      capture: true,
    })
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside, {
        capture: true,
      })
    }
  }, [commentViewReceive])

  const setCommentTable = async (select_row: any) => {
    const getCommentsData = await dispatch(
      getBetaTradeReportComments(
        select_row.id,
        select_row.orderNumber,
        select_row.orderDate
      )
    )
    if (
      getCommentsData &&
      getCommentsData.betaShortSaleTradeCommentsViewModel
    ) {
      setTableCommmentsData([
        ...getCommentsData.betaShortSaleTradeCommentsViewModel,
      ])
    }
  }

  const toggleViewComments = async (select_row: any) => {
    setSelectedRow(select_row)
    setComment('')
    if (!toggleComments) {
      await setCommentTable(select_row)
    }
    SetToggleComments(!toggleComments)
  }
  const commentsOnChange = (e: any) => {
    const { value } = e.target
    setComment(value)
  }

  const commentTrade = async () => {
    const request = {
      orderId: selectedRow.id,
      orderNumber: selectedRow.orderNumber,
      orderDate: selectedRow.orderDate,
      comments: comment,
    }
    await dispatch(saveBetaTradeReportComment(request))
    setComment('')
    await setCommentTable(selectedRow)
  }

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getSettlementShortSalesDetails('Beta', {
          ...commonTabProps,
          symbol: commonTabProps.symbol === '' ? null : commonTabProps.symbol,
          cusip: commonTabProps.cusip === '' ? null : commonTabProps.cusip,
          accountNumber:
            commonTabProps.accountNumber === ''
              ? null
              : commonTabProps.accountNumber,
          quantity:
            commonTabProps.quantity === '' ? null : commonTabProps.quantity,
        })
      )
    }
    commonTabProps.applySearchClick && fetchData()
    setAccessFlag(getAccessFlag(['SS-RWX', 'OM-RWX']))
  }, [])

  return (
    <>
      <Table
        hidePagination={false}
        heading={'Short Sales Trade Details'}
        data={SettlementShortSaleData?.length ? SettlementShortSaleData : []}
        totalCount={ShortSalesTotalCount ? ShortSalesTotalCount.data : 0}
        columns={shortSalesDetailsColumns(toggleViewComments, accessFlag)}
      />
      {toggleComments && (
        <PopUpSideBar ref={commentViewReceive}>
          <Comments
            tableCommmentsData={tableCommmentsData}
            commentsOnChange={commentsOnChange}
            comment={comment}
            commentTrade={commentTrade}
            ftrRowData={selectedRow}
            writeExecuteFlag={accessFlag}
          />
        </PopUpSideBar>
      )}
    </>
  )
}

export default TradeReportDetails
