export const RegShoColumnsAudit = (columnId: string) => {
  return [
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Record Type',
      accessor: 'recordType',
    },
    {
      Header: 'Cusip',
      accessor: 'cusip',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    // {
    //   Header: 'AllocationType',
    //   accessor: 'allocationType',
    // },
    {
      Header: 'Activity',
      accessor: 'activity',
    },
    // {
    //   Header: 'ID NO',
    //   accessor: `${columnId}`,
    // },
    // {
    //   Header: 'FieldChanged',
    //   accessor: 'fieldChanged',
    // },
    {
      Header: 'Old Value',
      accessor: 'oldValue',
    },
    {
      Header: 'New Value',
      accessor: 'newValue',
    },
    {
      Header: 'Allocation Type',
      accessor: 'allocationType',
    },
    {
      Header: 'Activity Date',
      accessor: 'activityDate',
    },
    {
      Header: 'Action',
      accessor: 'action',
    },
    {
      Header: 'CreatedBy',
      accessor: 'createdBy',
    },
    {
      Header: 'CreatedOn',
      accessor: 'createdOn',
    },
    {
      Header: 'Fail Date',
      accessor: 'failDate',
    },
  ]
}
