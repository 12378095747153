import {
  LottieWrapper,
  PopUpStyleLoader,
  SummaryCardContainerTradeBreaksTrend,
  TableChartWidgetContainerByTrend,
  TitleContainer,
} from './styles'
import { SetStateAction, useEffect, useState } from 'react'
import { authApiCall } from '../../../../utils/api'
import MultipleDropDown from './multipleDropDown'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import Label from '../../../label/Label'
import VerticalBarChart from '../../../verticalCharBar/verticalChat'

const ErrorTrend = () => {
  const [source, setSource] = useState('Beta')
  const [type, setType] = useState('FeedBack')
  const [loading, setLoading] = useState<boolean>(false)
  const [barLabel, setBarLabel] = useState<[]>([])
  const [barData, setBarData] = useState<[]>([])
  const [interval, setInterval] = useState('Daily')

  const getTradeBreakByTrend = async () => {
    try {
      const { data, status } = await authApiCall.get(
        `opsdashboardapi/OpsDashboard/Get_CAISErrorsTrendData?source=${source}&type=${type}&intervalType=${interval}&intervals=${5}`
      )
      if (status === 200) {
        await setLoading(false)
        setBarData(data.cAISErrorTrendDataViewModels)
        const legendLabels =
          data.cAISErrorTrendDataViewModels?.length &&
          data.cAISErrorTrendDataViewModels.map(
            (m: { year: string; yearOrMonthOrWeek: string }) =>
              m.yearOrMonthOrWeek
          )
        setBarLabel(legendLabels)
      }
    } catch (e) {
      await setLoading(false)
      const error = e
    }
  }
  useEffect(() => {
    getTradeBreakByTrend()
  }, [source, type, interval])

  const DropDownOptions = [
    {
      label: 'Source',
      attribute: 'Source',
      element: 'select',
      options: ['Beta', 'P3'],
      onChange: async (e: { target: { value: SetStateAction<string> } }) => {
        setSource(e.target.value)
      },
    },
    {
      label: 'Type',
      attribute: 'Type',
      element: 'select',
      options: ['FeedBack', 'Submission'],
      onChange: async (e: { target: { value: SetStateAction<string> } }) => {
        setType(e.target.value)
      },
    },
    {
      label: 'Interval',
      attribute: 'Interval',
      element: 'select',
      options: ['Daily', 'Weekly', 'MoM', 'QoQ'],
      onChange: async (e: { target: { value: SetStateAction<string> } }) => {
        setInterval(e.target.value)
      },
    },
  ]

  return (
    <>
      <TableChartWidgetContainerByTrend>
        <TitleContainer>FeedBack/Submission Error Trend</TitleContainer>
        <MultipleDropDown dropDowns={DropDownOptions} />
        <SummaryCardContainerTradeBreaksTrend>
          <VerticalBarChart
            data={barData.map((item: { count: any }) => item.count)}
            labels={barLabel}
            backgroundColor={['#77a8e4']}
            barLabel={'Count'}
          />
        </SummaryCardContainerTradeBreaksTrend>
        {loading && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <div style={{ marginLeft: '10px' }}>
              <Label> Loading...</Label>
            </div>
          </PopUpStyleLoader>
        )}
      </TableChartWidgetContainerByTrend>
    </>
  )
}

export default ErrorTrend
