/* eslint-disable react/prop-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  GoToPageWrapper,
  NextPriviousWrapper,
  PaginationContainer,
  RootContainerTable,
  ViewColumnsContainer,
  CheckboxStyle,
} from './styles'
import {
  useTable,
  useSortBy,
  usePagination,
  useRowSelect,
  useMountedLayoutEffect,
} from 'react-table'
import { useEffect, useState } from 'react'
import Label from '../../../../components/label/Label'
import { COLORS } from '../../../../theme/Colors'
import Button from '../../../../components/button/Button'
import EmailModal from '../SendEmail'
import AddEmail from '../AddContact'
import { ToastWrapper } from '../styles'
import { Toast } from '../../../../components/toast/Toast'
import { getAccessFlag } from '../../../../utils/globalFunctions'

const IndeterminateCheckbox = ({ ...rest }) => {
  return (
    <>
      <CheckboxStyle type="checkbox" {...rest} />
    </>
  )
}

interface TableProps {
  data: any
  columns: { Header: string; accessor: string }[]
  totalRecords: number
  correspondentsID: string
  reportRunDate: string
  type: string
  accountNum: string
}
const CreateTable = ({
  data,
  columns,
  totalRecords,
  reportRunDate,
  type,
}: TableProps) => {
  const [pageNumberCall, setPageNumberCall] = useState<number>(1)
  const controlledPageCount: number = Math.ceil(totalRecords / 20)
  const [requestSubmit, setRequestSubmit] = useState<{
    status: boolean | null
    text: string
  }>({
    status: null,
    text: '',
  })
  const [showEmailPopup, setShowEmailPopup] = useState<boolean>(false)
  const [showAddEmail, setShowAddEmail] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any>()
  const writeExecuteFlag = getAccessFlag(['AS-RWX', 'OM-RWX'])
  const INITIAL_SELECTED_ROW_IDS = {}
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    prepareRow,
    state,
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data,
      autoResetPage: false,
      initialState: {
        pageIndex: 0,
        pageSize: 50,
        selectedRowIds: INITIAL_SELECTED_ROW_IDS,
      },
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
    useRowSelect,

    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: '2%',
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox {...getToggleAllPageRowsSelectedProps()} />
            </div>
          ),
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
            </div>
          ),
        },
        ...columns,
      ])
    }
  )

  const { pageIndex, selectedRowIds } = state


  useMountedLayoutEffect(() => {
    const selectedIds = Object.keys(selectedRowIds)
    const selectedRowsData = selectedIds
      .map((x) => data[x])
      .filter(function (x) {
        return x != null
      })
    setSelectedRows(selectedRowsData)
  }, [selectedRowIds])

  return (
    <>
      {requestSubmit.status !== null && (
        <ToastWrapper>
          <Toast
            text={requestSubmit.text}
            type={requestSubmit.status ? 'success' : 'danger'}
            openStatusCallback={(status: boolean) =>
              setRequestSubmit({
                status: status ? status : null,
                text: '',
              })
            }
          />
        </ToastWrapper>
      )}
      <ViewColumnsContainer>
      <Button
          padding={'4px 16px'}
          borderRadius={'4px'}
          type={'button'}
          disabled={!writeExecuteFlag}
          color={COLORS.Background.Primarytext}
          style={{ whiteSpace: 'nowrap' }}
          bgColor={'transparent'}
          onClick={() => {
           //setShowAddEmail(!showAddEmail)
          }}
        >
         Send API
        </Button>
        <Button
          padding={'4px 16px'}
          borderRadius={'4px'}
          type={'button'}
          disabled={!writeExecuteFlag}
          color={COLORS.Background.Primarytext}
          style={{ whiteSpace: 'nowrap' }}
          bgColor={'transparent'}
          onClick={() => {
           setShowAddEmail(!showAddEmail)
          }}
        >
          Add Contact
        </Button>
        {showAddEmail && (
          <AddEmail setShowAddEmail={setShowAddEmail}></AddEmail>
        )}
        {selectedRows && selectedRows.length > 0 ? (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            disabled={!writeExecuteFlag}
            type={'button'}
            color={COLORS.Background.Primarytext}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
            onClick={() => {
               setShowEmailPopup(!showEmailPopup)
            }}
          >
            Send Email
          </Button>
        ) : (
          <Button
            padding={'4px 16px'}
            borderRadius={'4px'}
            type={'button'}
            color={COLORS.Background.Neutral40}
            borderColor={COLORS.Background.Neutral40}
            style={{ whiteSpace: 'nowrap' }}
            bgColor={'transparent'}
          >
            Send Email
          </Button>
        )}

        {showEmailPopup && (
          <EmailModal
            fromDate={reportRunDate}
            type={type}
            selectedRows={selectedRows}
            toggleAllRowsSelected={toggleAllRowsSelected}
            setShowEmailPopup={setShowEmailPopup}
          ></EmailModal>
        )}
      </ViewColumnsContainer>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, key) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={key}>
              {headerGroup.headers.map((column: any, index) => (
                <th key={index}>{column.render('Header')}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, i) => {
                  return (
                    <td {...cell.getCellProps()} key={i}>
                      {cell.render('Cell')}
                    </td>
                  )
                })}
              </tr>
            )
          })}
        </tbody>
      </table>
      {data.length == 0 && (
        <>
          <Label fontWeight={700} margin="5% 0 0 50%">
            No Records Found
          </Label>
          <Label fontWeight={700} margin="0.5% 0 0 49%">
            No Exceptions Identified
          </Label>
        </>
      )}

      <PaginationContainer>
        <Label>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{' '}
        </Label>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <GoToPageWrapper>
          <Label>Go to page:</Label>
          <input
            type="number"
            min="1"
            max={pageOptions.length}
            defaultValue={pageNumberCall}
            onChange={(e) => {
              if (Number(e.target.value) <= pageOptions.length) {
                e.stopPropagation()
                const pageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0
                gotoPage(pageNumber)
                setPageNumberCall(pageNumber + 1)
              }
            }}
            style={{ width: '50px' }}
          />
        </GoToPageWrapper>
        <span
          style={{
            marginLeft: '20px',
            color: `${COLORS.Icons.NeutralIconInverted}`,
          }}
        >
          |
        </span>
        <NextPriviousWrapper>
          <button
            onClick={() => {
              previousPage()
            }}
            disabled={!canPreviousPage}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Previous
          </button>
          <button
            onClick={() => {
              nextPage()
            }}
            disabled={!canNextPage}
            style={{
              border: 'none',
              background: `${COLORS.Icons.NeutralIconInverted}`,
              cursor: 'pointer',
            }}
          >
            Next
          </button>
        </NextPriviousWrapper>
      </PaginationContainer>
    </>
  )
}

const ReOrgTable = ({
  data,
  columns,
  totalRecords,
  correspondentsID,
  reportRunDate,
  type,
  accountNum,
}: TableProps) => {
  return (
    <RootContainerTable>
      <CreateTable
        data={data}
        columns={columns}
        correspondentsID={correspondentsID}
        reportRunDate={reportRunDate}
        type={type}
        totalRecords={totalRecords}
        accountNum={accountNum}
      />
    </RootContainerTable>
  )
}

export default ReOrgTable
