/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  settlementShortSales: any
  settlementShortSalesSummary: any
  shortSalesTotalCount: number
  shortSalesTotalCountSummary: number
  isLoading: boolean
  action: string
  addComment: any
  comments: any
  httpErrorMessage: string
  successMessage: string
}

let initialState: State = {
  settlementShortSales: {},
  settlementShortSalesSummary: {},
  shortSalesTotalCountSummary: 0,
  shortSalesTotalCount: 0,
  isLoading: false,
  action: '',
  addComment: {},
  comments: {},
  httpErrorMessage: '',
  successMessage: '',
}

const { actions, reducer }: Slice = createSlice({
  name: 'settlement-short-sales',
  initialState,
  reducers: {
    SetSettlementShortSalesDetails: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        settlementShortSales: action.payload.data.betaShortSaleTradeModel,
      }
    },
    SetSettlementShortSalesSummary: (
      state: State,
      action: PayloadAction<any>
    ) => {
      return {
        ...state,
        settlementShortSalesSummary:
          action.payload.data.betaShortSaleSummaryModel,
      }
    },

    SetIsLoading: (state: State, action: PayloadAction<boolean>) => {
      return {
        ...state,
        isLoading: action.payload,
      }
    },
    SetAction: (state: State, action: PayloadAction<string>) => {
      return {
        ...state,
        action: action.payload,
      }
    },
    SetCommentResponse: (state: State, action: PayloadAction<boolean>) => {
      state.addComment = action.payload
    },
    setBetaTradeReportComments: (state: State, action: PayloadAction<any>) => {
      state.comments = action.payload.data
    },
    setShortSalesTotalCount: (state: State, action: PayloadAction<number>) => {
      state.shortSalesTotalCount = action.payload
    },
    setShortSalesSummaryTotalCount: (
      state: State,
      action: PayloadAction<number>
    ) => {
      state.shortSalesTotalCountSummary = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  SetAction,
  SetSettlementShortSalesDetails,
  SetSettlementShortSalesSummary,
  SetIsLoading,
  SetCommentResponse,
  setBetaTradeReportComments,
  setShortSalesTotalCount,
  setShortSalesSummaryTotalCount,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getSettlementShortSalesDetails: any =
  (system: string, payload: any) => async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/BuyInIntents/BetaShortSaleTradeReport`,
        { ...payload, pageNumber: 0, pageSize: 0 }
      )

      if (status === 200) {
        if (data.errorResponse.statusCode == null) {
          dispatch(
            SetSettlementShortSalesDetails({
              data: data,
            })
          )
          dispatch(
            setShortSalesTotalCount({
              data: data.totalCount,
            })
          )
        } else {
          dispatch(
            SetSettlementShortSalesDetails({
              data: data,
            })
          )
          dispatch(setHttpErrorMessage(data.errorResponse.message))
        }

        dispatch(SetIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(
        SetSettlementShortSalesDetails({
          data: {},
        })
      )
      dispatch(SetIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }

export const getSettlementShortSalesSummary: any =
  (system: string, payload: any) => async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsfailprocessapi/BuyInIntents/BetaShortSaleTradeReportSummary`,
        { ...payload, pageNumber: 0, pageSize: 0 }
      )

      if (status === 200) {
        if (data.errorResponse.statusCode == null) {
          dispatch(
            SetSettlementShortSalesSummary({
              data: data,
            })
          )
          dispatch(
            setShortSalesSummaryTotalCount({
              data: data.totalCount,
            })
          )
        } else {
          dispatch(
            SetSettlementShortSalesSummary({
              data: data,
            })
          )
          dispatch(setHttpErrorMessage(data.errorResponse.message))
        }

        dispatch(SetIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(
        SetSettlementShortSalesSummary({
          data: {},
        })
      )
      dispatch(SetIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }

export const getBetaTradeReportComments: any =
  (orderId: number, orderNumber: number, orderDate: string) =>
  async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        `opsfailprocessapi/BuyInIntents/GetBetaShortSaleTradeComments?orderId=${orderId}&OrderNumber=${orderNumber}&OrderDate=${orderDate}`
      )
      if (status === 200) {
        dispatch(
          setBetaTradeReportComments({
            data: data,
          })
        )
        dispatch(SetIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(SetIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
    }
  }

export const saveBetaTradeReportComment: any =
  (request: any) => async (dispatch: Dispatch) => {
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/AddBetaShortSaleTradeComments',
        request
      )

      if (status === 200) {
        dispatch(
          SetCommentResponse({
            data: data,
          })
        )
        dispatch(setSuccessMessage('Comment Added Successfully'))
        return data
      }
    } catch (e: any) {
      dispatch(
        SetCommentResponse({
          data: { tradeBreakActionModels: [] },
        })
      )
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return {
        data: {
          actionCountModel: [
            {
              actionCount: 0,
            },
          ],
        },
      }
    }
  }

export const getBetaShortSaleExcel: any =
  (fileName: string, tabName: string, payload: {}) =>
  async (dispatch: Dispatch) => {
    dispatch(SetIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/ExportBetaShortSaleTradeReport',
        { ...payload, pageNumber: 0, pageSize: 0 },
        { responseType: 'blob' }
      )
      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${fileName}.xlsx`)
        document.body.appendChild(link)
        link.click()
        dispatch(SetIsLoading(false))
        dispatch(setSuccessMessage('File Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      dispatch(SetIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      return null
    }
  }

export const SettlementShortSummarySelector = (store: StoreState) =>
  store.SettlementShortSales.SettlementShortSale.settlementShortSalesSummary

export const SettlementShortSalesSelector = (store: StoreState) =>
  store.SettlementShortSales.SettlementShortSale.settlementShortSales

export const actionSelector = (store: StoreState) =>
  store.SettlementShortSales.SettlementShortSale.action

export const loadingSelector = (store: StoreState) =>
  store.SettlementShortSales.SettlementShortSale.isLoading

export const ShortSalesTotalCountSelector = (store: StoreState) =>
  store.SettlementShortSales.SettlementShortSale.shortSalesTotalCount

export const ShortSalesTotalCountSummarySelector = (store: StoreState) =>
  store.SettlementShortSales.SettlementShortSale.shortSalesTotalCountSummary

export const betaTradeReportCommentsSelector = (store: StoreState) =>
  store.SettlementShortSales.SettlementShortSale.comments

export const HttpErrorMessageSelector = (store: StoreState) =>
  store.SettlementShortSales.SettlementShortSale.httpErrorMessage

export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.SettlementShortSales.SettlementShortSale.successMessage

export default reducer
