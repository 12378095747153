import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { StyledTableContainer } from './OutStandingStyle'

import { getAccessFlag } from '../../../utils/globalFunctions'
import Table from '../table/Table'
import { OutStandingColumns } from './OutStandingColumns'

interface IOutStandingDetailsProps {
  data: any
  totalCount: number
}

const OutStandingDetails = ({
  data,
  totalCount,
}: IOutStandingDetailsProps) => {
  const dispatch = useDispatch()
  const [accessFlag, setAccessFlag] = useState<boolean>(false)
useEffect(()=>{
  setAccessFlag(getAccessFlag(['SS-RWX','OM-RWX']))
},[])


  return (
    <StyledTableContainer>
     <Table
        hidePagination={false}
        heading={'Buy-In Execution Report'}
        data={data && data?.length ? data : []}
        totalCount={totalCount ? totalCount : 0}
        columns={OutStandingColumns}
      />
    </StyledTableContainer>
  )
}

export default OutStandingDetails
