import Lottie from 'lottie-react'
import Button from '../../../components/button/Button'
import Table from '../../../components/table/Table'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { COLORS } from '../../../theme/Colors'
import Loader from '../../../assets/lottiefiles/loader.json'
import {
  ActionIconContainer,
  DownloadButtonWrapper,
  LabelTextboxWrapeer,
  LottieWrapper,
  MarginLeftAutoSpace,
  PopUpStyleContent,
  PopUpStyleLoader,
  RootContainer,
  StyledSelectWrapper,
  StyledTableContainer,
  SvgButtonWrapper,
  ToastWrapper,
} from './styles'
import { Toast } from '../../../components/toast/Toast'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import {
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  LoadingSelector,
  addSuspenseAccount,
  currentDataSelector,
  getSuspenseAccountExcel,
  getSuspenseAccountReportData,
  setHttpErrorMessage,
  setSuccessMessage,
  updateAccountList,
} from '../../../store/operations-Control/suspense-account-report'
import Label from '../../../components/label/Label'
import SvgCancel from '../../../components/svg/logo/SvgCancel'
import Divider from '../../../components/divider/Divider'
import { Backgrounds } from '../../../shared/styles'
import { Textbox } from '../../../components/textbox/Textbox'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { getAccessFlag } from '../../../utils/globalFunctions'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import { SvgEdit } from '../../../components/svg/SvgEdit'

const StreetSettlementsSuspenseAccount = () => {
  const dispatch = useDispatch()
  const data = useSelector(currentDataSelector)
  const loading = useSelector(LoadingSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const [toggleInsert, SetToggleInsert] = useState<boolean>(false)
  const [toggleEdit, SetToggleEdit] = useState<boolean>(false)
  const [reloadTable, SetReloadTable] = useState<boolean>(false)
  const [checkAccountNumber, SetCheckAccountNumber] = useState<boolean>(false)
  const writeExecuteFlag = getAccessFlag(['OC-RWX', 'OM-RWX'])
  const [mapping, setMapping] = useState({
    id: 0,
    source: '',
    accountNumber: '',
    active: '',
    department: '',
  })

  const departmentOptions = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'Street Settlement',
      label: 'Street Settlement',
    },
    {
      value: 'Client Settlement',
      label: 'Client Settlement',
    },
    {
      value: 'ACATS',
      label: 'ACATS',
    },
    {
      value: 'Income Processing',
      label: 'Income Processing',
    },
    {
      value: 'Reorganization',
      label: 'Reorganization',
    },
  ]
  const activeOptions = [
    {
      value: 'Y',
      label: 'Yes',
    },
    {
      value: 'N',
      label: 'No',
    },
  ]

  const sourceOptions = [
    {
      value: 'Beta',
      label: 'Beta',
    },
    {
      value: 'P3',
      label: 'P3',
    },
  ]

  useEffect(() => {
    async function handleSearch() {
      const response = await dispatch(getSuspenseAccountReportData())
    }
    handleSearch()
  }, [reloadTable])

  const addAccount = () => {
    setMapping({
      id: 0,
      source: '',
      accountNumber: '',
      active: '',
      department: '',
    })
    SetToggleInsert(true)
  }

  const addNew = async (
    source: string,
    accountNumber: string,
    active: string,
    department: string
  ) => {
    SetToggleInsert(false)
    SetCheckAccountNumber(false)
    await dispatch(
      addSuspenseAccount(accountNumber, active, department, source)
    )
    await dispatch(getSuspenseAccountReportData())
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    })
  }

  const updateAccount = async (
    id: number,
    source: string,
    active: string,
    department: string
  ) => {
    SetToggleEdit(false)
    await dispatch(updateAccountList(id, active, department, source))
    await dispatch(getSuspenseAccountReportData())
  }

  const DownloadEXCEL = async () => {
    const response = await dispatch(
      getSuspenseAccountExcel(`StreetSettlementSuspenseAccount`)
    )
  }

  return (
    <RootContainer>
      <WDCard>
        <CardHeader
          label={
            'Suspense Accounts Crossreference Table For Intellimatch Interface'
          }
        />
      </WDCard>
      <StyledSelectWrapper style={{ justifyContent: 'flex-end' }}>
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          gap={'8px'}
          type={''}
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          disabled={!writeExecuteFlag}
          onClick={() => {
            addAccount()
          }}
        >
          Add New
        </Button>
        <DownloadButtonWrapper
          onClick={() => {
            DownloadEXCEL()
          }}
        >
          <SvgDownloadSmall
            fillColor={`${COLORS.UI.Primary50}`}
          ></SvgDownloadSmall>
          <WDStyledButtonText>
            <Label cursor="pointer">EXCEL</Label>
          </WDStyledButtonText>
        </DownloadButtonWrapper>
      </StyledSelectWrapper>
      <StyledTableContainer>
        <Table
          tableColumns={[
            {
              Header: 'Source',
              accessor: 'source',
            },
            {
              Header: 'Account Number',
              accessor: 'accountNumber',
            },
            {
              Header: 'Active',
              accessor: 'active',
            },
            {
              Header: 'Department',
              accessor: 'function',
            },
            {
              Header: 'Action',
              Cell: (table: {
                cell: { row: { index: number; original: any } }
              }) => {
                return (
                  <ActionIconContainer>
                    <span
                      role="presentation"
                      title="Edit"
                      style={{ cursor: 'pointer', width: '25px' }}
                      onClick={() => {
                        setMapping({
                          id: table.cell?.row?.original?.id,
                          source: table.cell?.row?.original?.source,
                          accountNumber:
                            table.cell?.row?.original?.accountNumber,
                          active: table.cell?.row?.original?.active,
                          department: table.cell?.row?.original?.function,
                        })

                        SetToggleEdit(true)
                      }}
                    >
                      <SvgEdit fillColor="#475569" />
                    </span>
                  </ActionIconContainer>
                )
              },
            },
          ]}
          tableData={
            data?.streetSettlementSuspenseAccount
              ? data?.streetSettlementSuspenseAccount
              : []
          }
          onClick={() => {
            //
          }}
        />
        {data?.streetSettlementSuspenseAccount?.length == 0 && (
          <>
            <Label fontWeight={700} margin="5% 0 0 50%">
              No Records Found
            </Label>
            <Label fontWeight={700} margin="0.5% 0 0 49%">
              No Exceptions Identified
            </Label>
          </>
        )}
        {loading && (
          <PopUpStyleLoader>
            <StyledSelectWrapper style={{ justifyContent: 'center' }}>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LottieWrapper style={{ top: '40.5%' }}>
                {' '}
                Loading...
              </LottieWrapper>
            </StyledSelectWrapper>
          </PopUpStyleLoader>
        )}
        {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
          <ToastWrapper>
            <Toast
              text={SuccessMessage || ErrorMessage}
              type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
              openStatusCallback={() => {
                dispatch(setHttpErrorMessage(''))
                dispatch(setSuccessMessage(''))
              }}
            />
          </ToastWrapper>
        )}
        {/*Insert*/}
        {toggleInsert && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              Add Account
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleInsert(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <LabelTextboxWrapeer>
                <Label color={'black'}>Source</Label>
                <SelectDropdown
                  height="32px"
                  buttonWidth="150px"
                  padding="4px 16px"
                  borderRadius="4px"
                  fw={100}
                  lh="24px"
                  fs="16px"
                  options={sourceOptions}
                  onChange={(options) => {
                    setMapping({ ...mapping, source: options })
                  }}
                  defaultValue={{
                    value: '',
                    label: '--Select--',
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>Account Number</Label>
                <Textbox
                  placeholder="Enter Account number"
                  type="number"
                  max="99999999"
                  onChange={(e) => {
                    setMapping({ ...mapping, accountNumber: e })
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>Active</Label>
                <SelectDropdown
                  height="32px"
                  buttonWidth="150px"
                  padding="4px 16px"
                  borderRadius="4px"
                  fw={100}
                  lh="24px"
                  fs="16px"
                  options={activeOptions}
                  onChange={(options) => {
                    setMapping({ ...mapping, active: options })
                  }}
                  defaultValue={{
                    value: '',
                    label: '--Select--',
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label
                  fontSize="16px"
                  fontWeight={400}
                  color={'#0F172A'}
                  lineHeight={'24px'}
                >
                  Department
                </Label>
                <SelectDropdown
                  height="32px"
                  buttonWidth="150px"
                  padding="4px 16px"
                  borderRadius="4px"
                  fw={100}
                  lh="24px"
                  fs="16px"
                  options={departmentOptions}
                  onChange={(options) => {
                    setMapping({ ...mapping, department: options })
                  }}
                  defaultValue={{
                    value: '',
                    label: '--Select--',
                  }}
                />{' '}
              </LabelTextboxWrapeer>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              {checkAccountNumber && (
                <Label color={COLORS.Background.Warning}>
                  *Please enter 8-digit Account Number
                </Label>
              )}
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleInsert(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>{' '}
              {mapping.source &&
              mapping.accountNumber &&
              mapping.active &&
              mapping.department ? (
                <Button
                  hoverBgColor={COLORS.Background.Primary70}
                  bgColor={COLORS.Background.Primarytext}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  hover={true}
                  type={''}
                  activeBgColor={COLORS.Background.Primaryactive}
                  borderColor={'transparent'}
                  onClick={() => {
                    mapping.accountNumber.length <= 8
                      ? addNew(
                          mapping.source,
                          mapping.accountNumber,
                          mapping.active,
                          mapping.department
                        )
                      : SetCheckAccountNumber(true)
                  }}
                >
                  Add
                </Button>
              ) : (
                <Button
                  bgColor={COLORS.Text.NeutralTextDisabled}
                  color={COLORS.Background.Primary}
                  padding={'4px 16px'}
                  borderRadius={'4px'}
                  type={''}
                  borderColor={'transparent'}
                >
                  Add
                </Button>
              )}
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
        {/*Edit*/}
        {toggleEdit && (
          <PopUpStyleContent>
            <StyledSelectWrapper
              style={{ padding: '8px 24px 8px 15px', fontWeight: '600' }}
            >
              Update Account
              <MarginLeftAutoSpace>
                <SvgButtonWrapper
                  onClick={() => {
                    SetToggleEdit(false)
                  }}
                >
                  <SvgCancel fillColor={COLORS.Icons.NeutralIcon}></SvgCancel>
                </SvgButtonWrapper>
              </MarginLeftAutoSpace>
            </StyledSelectWrapper>
            <Divider
              thickness={'1px'}
              horizontal={true}
              bgColor={Backgrounds.Gray110}
            />
            <StyledSelectWrapper style={{ padding: 15 }}>
              <LabelTextboxWrapeer>
                <Label color={'black'}>Source</Label>
                <SelectDropdown
                  height="32px"
                  buttonWidth="150px"
                  padding="4px 16px"
                  borderRadius="4px"
                  fw={100}
                  lh="24px"
                  fs="16px"
                  options={sourceOptions}
                  onChange={(options) => {
                    setMapping({ ...mapping, source: options })
                  }}
                  defaultValue={{
                    value: mapping.source,
                    label: mapping.source,
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>Account Number</Label>
                <Textbox
                  placeholder="Enter Account number"
                  defaultValue={mapping.accountNumber}
                  disabled={true}
                  // onChange={(e) => {
                  //   setMapping({ ...mapping, accountNumber: e })
                  // }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label color={'black'}>Active</Label>
                <SelectDropdown
                  height="32px"
                  buttonWidth="150px"
                  padding="4px 16px"
                  borderRadius="4px"
                  fw={100}
                  lh="24px"
                  fs="16px"
                  options={activeOptions}
                  onChange={(options) => {
                    setMapping({ ...mapping, active: options })
                  }}
                  defaultValue={{
                    value: mapping.active,
                    label: mapping.active,
                  }}
                />
              </LabelTextboxWrapeer>
              <LabelTextboxWrapeer>
                <Label
                  fontSize="16px"
                  fontWeight={400}
                  color={'#0F172A'}
                  lineHeight={'24px'}
                >
                  Department
                </Label>
                <SelectDropdown
                  height="32px"
                  buttonWidth="150px"
                  padding="4px 16px"
                  borderRadius="4px"
                  fw={100}
                  lh="24px"
                  fs="16px"
                  options={departmentOptions}
                  onChange={(options) => {
                    setMapping({ ...mapping, department: options })
                  }}
                  defaultValue={{
                    value: mapping.department,
                    label: mapping.department,
                  }}
                />{' '}
              </LabelTextboxWrapeer>
            </StyledSelectWrapper>
            <StyledSelectWrapper
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 20,
              }}
            >
              <Button
                padding={'4px 16px'}
                borderRadius={'4px'}
                type={'button'}
                color={COLORS.Background.Primarytext}
                bgColor={'transparent'}
                onClick={() => {
                  SetToggleEdit(false)
                }}
              >
                <Label fontWeight={600}> Cancel</Label>
              </Button>{' '}
              <Button
                hoverBgColor={COLORS.Background.Primary70}
                bgColor={COLORS.Background.Primarytext}
                color={COLORS.Background.Primary}
                padding={'4px 16px'}
                borderRadius={'4px'}
                hover={true}
                type={''}
                activeBgColor={COLORS.Background.Primaryactive}
                borderColor={'transparent'}
                onClick={() => {
                  updateAccount(
                    mapping.id,
                    mapping.source,
                    mapping.active,
                    mapping.department
                  )
                }}
              >
                Update
              </Button>
            </StyledSelectWrapper>
          </PopUpStyleContent>
        )}
      </StyledTableContainer>
    </RootContainer>
  )
}

export default StreetSettlementsSuspenseAccount
