import { useEffect, useState } from 'react'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  PopUpStyleLoader,
  StyledSelectWrapper,
  LottieWrapper,
  StyledTableContainer,
} from './RegShoHistoryAuditStyles'
import RegShoHistoryAuditSearchPanel from './RegShoHistoryAuditSearchPanel'
import {
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  RegShoHistoryDataSelector,
  TotalCountSelector,
  loadingSelector,
  setSuccessMessage,
  sethttpErrorMessageSummary,
} from '../../../store/settlement/regSho-AuditTrail'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import Loader from '../../../assets/lottiefiles/loader.json'
import { RegShoColumnsAudit } from './RegShoAuditColumns'
import TableRegSHOHistory from './regshiHistoryTable/Table'
import { ToastWrapper } from '../SettlementCommonStyles'
import { Toast } from '../../../components/toast/Toast'

export interface IRegShoHistoryAuditSearchFilters {
  fromDate: string
  toDate: string
  cusip: string
  symbol: string
  source: string
  type: string
  pageName: string
  allocationType: string
  columnId: string
}

const RegShoHistoryAudit = () => {
  const InitialFilterParams = {
    fromDate: new Date().toISOString().split('T')[0],
    toDate: new Date().toISOString().split('T')[0],
    cusip: '',
    symbol: '',
    source: 'Beta',
    type: 'All',
    pageName: 'All',
    allocationType: 'All',
    columnId: 'regShoL1buyInsId',
  }
  const dispatch = useDispatch()
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [searchParams, setSearchParams] = useState({ ...InitialFilterParams })
  const [tableColumn, setTableColumn] = useState<any>('')
  const [showTable, setShowTable] = useState(false)
  const Data = useSelector(RegShoHistoryDataSelector)
  const TotalCount = useSelector(TotalCountSelector)
  const Loading = useSelector(loadingSelector)

  useEffect(() => {
    setTableColumn(RegShoColumnsAudit(searchParams.columnId))
  }, [searchParams])

  return (
    <>
      <RootContainer>
        {Loading && (
          <PopUpStyleLoader>
            <StyledSelectWrapper style={{ flexDirection: 'column' }}>
              <LottieWrapper>
                <Lottie animationData={Loader} loop={true} />
              </LottieWrapper>
              <LottieWrapper style={{ left: '53.5%' }}>
                {' '}
                Loading...
              </LottieWrapper>
            </StyledSelectWrapper>
          </PopUpStyleLoader>
        )}
        <StyledPanelHead>
          <StyledPanelHeadTitle>
            Reg SHO Historical Audit Trail
          </StyledPanelHeadTitle>
        </StyledPanelHead>
        <RegShoHistoryAuditSearchPanel
          searchParams={searchParams}
          setsearchParams={setSearchParams}
          setShowTable={setShowTable}
        />
        {showTable && (
          <StyledTableContainer>
            <TableRegSHOHistory
              hidePagination={false}
              heading={searchParams.pageName}
              data={Data && Data?.length ? Data : []}
              totalCount={TotalCount ? TotalCount.data : 0}
              columns={tableColumn}
            />
          </StyledTableContainer>
        )}
        {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
          <ToastWrapper>
            <Toast
              text={SuccessMessage || ErrorMessage}
              type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
              openStatusCallback={() => {
                dispatch(setSuccessMessage(''))
                dispatch(sethttpErrorMessageSummary(''))
              }}
            />
          </ToastWrapper>
        )}
      </RootContainer>
    </>
  )
}

export default RegShoHistoryAudit
