/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react'
import {
  AlertLabel,
  LottieWrapper,
  ParentWrapper,
  SummaryCardContainerTradeBreaksAlerts,
  TitleContainer,
  VerticalAlertsContainer,
} from './styles'
import { authApiCall } from '../../../../utils/api'
import { PopUpStyleLoader } from '../../../../pages/tradeRecon/styles'
import { useNavigate } from 'react-router-dom'
import { configJSONDashboard } from '../../../../utils/config'
import Label from '../../../label/Label'
import Lottie from 'lottie-react'
import Loader from '../../../../assets/lottiefiles/loader.json'
import AlertsDetails from './AlertsDetails'

const Alerts = (props: { isIntraday?: boolean }) => {
  const navigate = useNavigate()
  const [loading, isLoading] = useState<boolean>(true)
  const [intradayCardTitle, setIntradayCardTitle] = useState<any>([])
  const [eodCardTitle, setEodCardTitle] = useState<any>([])

  useEffect(() => {
    const getAlerts = async () => {
      let api = ''
      if (props?.isIntraday) {
        api = configJSONDashboard.IntraDayTradeBreaks.alerts.kpi
      } else {
        api = configJSONDashboard.TradeBreaks.alerts.kpi
      }
      try {
        const { data, status } = await authApiCall.get(api)
        if (status === 200) {
          const intradayAlertDetails: any = []
          const eodAlertDetails: any = []
          if (data?.alertsandNotificationViewModels?.length) {
            if (props?.isIntraday) {
              data?.alertsandNotificationViewModels.map((element: any) => {
                if (element.category === 'IntradayBreaks') {
                  intradayAlertDetails.push(element)
                }
                if (element.category === 'TradeBreaks') {
                  eodAlertDetails.push(element)
                }
              })
            } else {
              data?.alertsandNotificationViewModels.map((element: any) => {
                if (element.category === 'TradeBreaks') {
                  eodAlertDetails.push(element)
                }
              })
            }
          }
          setIntradayCardTitle(intradayAlertDetails)
          setEodCardTitle(eodAlertDetails)
          isLoading(false)
        }
      } catch (e) {
        const error = e
        isLoading(false)
      }
    }
    getAlerts()
  }, [])

  const addBreadCrumb = async (date: any) => {
    let breadCrumbArray = []
    if (props?.isIntraday) {
      breadCrumbArray = [
        {
          key: 'Dashboard',
          link: '/intradayTradeReconDashboard',
          active: false,
        },
        {
          key: 'IntradayTradeBreaksByEC',
          link: `/intradayTradeBreaksByEC`,
          active: true,
          passProps: { fromDate: date, toDate: date },
        },
      ]
      sessionStorage.setItem('breadCrumbData', JSON.stringify(breadCrumbArray))
      navigate('/intradayTradeBreaksByEC', {
        state: {
          passProps: { fromDate: date, toDate: date },
        },
      })
    }
  }

  const eodBreadCrumb = async (date: any) => {
    let breadCrumbArray = []
    breadCrumbArray = [
      {
        key: 'Dashboard',
        link: '/tradeReconDashboard',
        active: false,
      },
      {
        key: 'TradeBreaksByEC',
        link: `/tradeBreaksByEC`,
        active: true,
        passProps: { fromDate: date, toDate: date },
      },
    ]
    sessionStorage.setItem('breadCrumbData', JSON.stringify(breadCrumbArray))
    navigate('/tradeBreaksByEC', {
      state: {
        passProps: { fromDate: date, toDate: date },
      },
    })
  }

  const viewIdentified = async (date: any) => {
    let breadCrumbArray: any = []
    if (props?.isIntraday) {
      breadCrumbArray = [
        {
          key: 'Dashboard',
          link: '/intradayTradeReconDashboard',
          active: false,
        },
        {
          key: 'IntradayTradePageMPID',
          link: `/intradayTradePageMPID`,
          active: true,
          passProps: { fromDate: date, toDate: date },
        },
      ]
    }
    sessionStorage.setItem('breadCrumbData', JSON.stringify(breadCrumbArray))
    navigate('/intradayTradePageMPID', {
      state: {
        passProps: { fromDate: date, toDate: date, isApproved: true },
      },
    })
  }
  const eodViewIdentified = async (date: any) => {
    let breadCrumbArray: any = []
    breadCrumbArray = [
      {
        key: 'Dashboard',
        link: '/tradeReconDashboard',
        active: false,
      },
      {
        key: 'TradeBreaksByMPID',
        link: `/tradePageMPID`,
        active: true,
        passProps: { fromDate: date, toDate: date },
      },
    ]
    sessionStorage.setItem('breadCrumbData', JSON.stringify(breadCrumbArray))
    navigate('/tradePageMPID', {
      state: {
        passProps: { fromDate: date, toDate: date, isApproved: true },
      },
    })
  }
  return (
    <>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <SummaryCardContainerTradeBreaksAlerts>
        <TitleContainer>Alerts and Notifications</TitleContainer>
        <VerticalAlertsContainer>
          {props.isIntraday && (
            <ParentWrapper>
              <AlertLabel>INTRADAY</AlertLabel>
              <AlertsDetails
                cardTitle={intradayCardTitle}
                addBreadCrumb={addBreadCrumb}
                viewIdentified={viewIdentified}
              ></AlertsDetails>
            </ParentWrapper>
          )}
          <ParentWrapper>
            <AlertLabel>EoD</AlertLabel>
            <AlertsDetails
              cardTitle={eodCardTitle}
              addBreadCrumb={eodBreadCrumb}
              viewIdentified={eodViewIdentified}
            ></AlertsDetails>
          </ParentWrapper>
        </VerticalAlertsContainer>
      </SummaryCardContainerTradeBreaksAlerts>
    </>
  )
}

export default Alerts
