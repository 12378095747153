/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Label from '../../../../../components/label/Label'
import Lottie from 'lottie-react'
import Loader from '../../../../../assets/lottiefiles/loader.json'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  getAssetType,
  GetDeskList,
  getTradeBreak,
  tradeManageSelector,
} from '../../../../../store/trade-management/trade-management'
import {
  RootContainer,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledTableContainer,
  PopUpStyleLoader,
  LottieWrapper,
} from '../../../styles'
import Table from '../../../table/TableWithSelect'
import {
  entityCodeSelector,
  getEntityCode,
} from '../../../../../store/Equity-break/Equity-break'
import { SvgEye } from '../../../../../components/svg/SvgEye'
import TradeBreakByMPIDFilter from './tradeBreaksFilter'
import TableColumns from '../../table/tableColumnsByMPID'
import {
  ExcelDownload880,
  FilterOnChange,
  HandleSearch,
  getEntityCodeArray,
  setDataTable,
} from '../../../../../helper/tradeRecon/tradeRecon'
import TableTopBar from './tableTopBar'
import TableFooter from './tradeBreakActions'
import CommentTradeByMpid from '../../comment/commentTrade'
import { getAccessFlag } from '../../../../../utils/globalFunctions'
const TradeManagementByMPID = () => {
  const commentView = useRef<HTMLInputElement>()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const entityCode = useSelector(entityCodeSelector)
  const tradeData = useSelector(tradeManageSelector)
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(true)
  const [displayFlag, setDisplayFlag] = useState<boolean>(true)
  const [writeExecuteFlag, setWriteExecuteFlag] = useState<boolean>(true)
  const [toggleComments, SetToggleComments] = useState<boolean>(false)
  const TodayDate = new Date();
  TodayDate.setDate(TodayDate.getDate() - 1);
  const [input, setInput] = useState({
    startDate: TodayDate.toISOString().split('T')[0],
    endDate: TodayDate.toISOString().split('T')[0],
    mpid: '',
    symbol: '',
    cusip: '',
    settleDate: '',
  })
  const [assetType, setAssetType] = useState<any>({
    value: '',
    label: '  --Select--  ',
  })

  const [desk, setDesk] = useState<any>({
    value: '',
    label: '  --Select--  ',
  })
  const [checkFilter, setCheckFilter] = useState<any>({
    all: false,
    approve: false,
    reject: false,
    notAct: true,
    cancelled: false,
    notified: false,
  })
  const [checkFilterCount, setCheckFilterCount] = useState<any>({
    all: 0,
    approve: 0,
    reject: 0,
    notAct: 0,
    cancelled: 0,
    notified: 0,
  })
  const [entityType, setentityType] = useState<any>([])
  const entityOption: any = []
  entityCode &&
    entityCode.forEach((element: any, index: any) => {
      entityOption.push({
        value: element.entityCode,
        label: `${element.entityCode}-${element.firmName}`,
      })
    })
  const [tableData, setTableData] = useState<any>([])
  const [orginalTableData, setOriginalTableData] = useState<any>([])
  const [filterKey, setFilterKey] = useState<any>('NA')
  const [isSelectedRow, setIsSelectedRow] = useState<boolean>(false)
  const [tradeRowData, setTradeData] = useState<any>()
  const [showFilter, setShowFilters] = useState<boolean>(false)

  useEffect(() => {
    setWriteExecuteFlag(getAccessFlag(['TR-RWX', 'OM-RWX']))
  }, [])
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event: any) => {
      if (
        commentView?.current &&
        !commentView?.current?.contains(event.target)
      ) {
        SetToggleComments(false)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [commentView])
  useEffect(() => {
    const paramValues: any = location.state
    if (
      paramValues?.passProps &&
      paramValues.passProps.entityCode &&
      paramValues.passProps.entityCode.length
    ) {
      const tempFilter = entityCode.find(
        (element: any) => paramValues.passProps.entityCode
      )
      const resonseData = [
        {
          value: paramValues.passProps.entityCode,
          label:
            entityCode && entityCode.length
              ? `${paramValues.passProps.entityCode}-${tempFilter?.firmName}`
              : paramValues.passProps.entityCode,
        },
      ]
      setentityType(resonseData)
    }
  }, [entityCode])

  const getTradeBreakByMPID = async (params: any) => {
    let reqInput = {
      startDate: input.startDate,
      endDate: input.endDate,
      mpid: '',
      symbol: '',
      cusip: '',
      settleDate: '',
    }
    const entityCodeArray: any = getEntityCodeArray(entityType)
    if (params && params.passProps) {
      const paramValues: any = params
      if (paramValues) {
        reqInput = {
          ...input,
          startDate: paramValues.passProps.fromDate,
          endDate: paramValues.passProps.toDate,
          symbol: paramValues.passProps.symbol,
          cusip: paramValues.passProps.cusip,
          settleDate: paramValues.passProps.settleDate
            ? paramValues.passProps.settleDate?.split('T')[0]
            : null,
        }
        await setInput(reqInput)
      }
      HandleSearch(
        reqInput,
        [paramValues.passProps.entityCode?.trim()],
        assetType,
        desk.value,
        dispatch
      ).then((resp) => {
        setDataTable(
          resp,
          params,
          setOriginalTableData,
          setCheckFilterCount,
          setTableData,
          filterKey
        )
        setLoading(false)
      })
    } else {
      const tradeDataBySymbol = await dispatch(
        getTradeBreak(
          'search',
          input.startDate,
          input.endDate,
          null,
          null,
          null,
          null,
          null,
          null
        )
      )
      setDataTable(
        tradeDataBySymbol,
        params,
        setOriginalTableData,
        setCheckFilterCount,
        setTableData,
        filterKey
      )
      setLoading(false)
    }
  }

  useEffect(() => {
    async function initialApiCall() {
      await dispatch(getAssetType())
      await dispatch(getEntityCode())
    await dispatch(GetDeskList())
    getTradeBreakByMPID(location.state)
    setShowFilters(true)
    }
    initialApiCall()
  
  }, [])

  const reset = async (tradeDataBySymbol: any, paramValues: any) => {
    setLoading(true)
    setAssetType({
      value: '',
      label: '--Select--',
    })
    setentityType([])
    setInput({
      startDate: TodayDate.toISOString().split('T')[0],
      endDate: TodayDate.toISOString().split('T')[0],
      mpid: '',
      symbol: '',
      cusip: '',
      settleDate: '',
    })
    await setDataTable(
      tradeDataBySymbol,
      paramValues,
      setOriginalTableData,
      setCheckFilterCount,
      setTableData,
      filterKey
    )
    setLoading(false)
  }

  const handleSearch = async () => {
    setLoading(true)
    const entityCodeArray: any = getEntityCodeArray(entityType)
    const params = location.state
    HandleSearch(input, entityCodeArray, assetType, desk.value, dispatch).then(
      (resp) => {
        setDataTable(
          resp,
          params,
          setOriginalTableData,
          setCheckFilterCount,
          setTableData,
          filterKey
        )
        setLoading(false)
      }
    )
  }

  const refreshData = async () => {
    setTableData([])
    setLoading(true)
    const params = location.state
    const entityCodeArray: any = getEntityCodeArray(entityType)
    setInput({ ...input, settleDate: '' })
    const reqInput = { ...input, settleDate: null }
    HandleSearch(
      reqInput,
      entityCodeArray,
      assetType,
      desk.value,
      dispatch
    ).then((resp) => {
      setDataTable(
        resp,
        params,
        setOriginalTableData,
        setCheckFilterCount,
        setTableData,
        filterKey
      )
      setLoading(false)
    })
  }

  const downloadExcel = async (params: any) => {
    ExcelDownload880(
      input,
      entityType,
      assetType,
      dispatch,
      setLoading,
      desk.value
    )
  }

  const onSelectedRowChange = (selectedRowsData: any) => {
    if (selectedRowsData?.length && !isSelectedRow) {
      setIsSelectedRow(true)
    } else if (!selectedRowsData?.length && isSelectedRow) {
      setIsSelectedRow(false)
    }
  }

  const editItem = (e: any) => {
    return (
      <span
        role="presentation"
        title="View"
        style={{ cursor: 'pointer', width: '25px' }}
        onClick={() => {
          const passProps = {
            fromDate: input.startDate,
            toDate: input.endDate,
            ...e.data[e.cell.row.id],
          }
          const data = sessionStorage.getItem('breadCrumbData')
          if (data) {
            const dataArray = JSON.parse(data)
            dataArray[dataArray.length - 1]['active'] = false
            dataArray[dataArray.length - 1].passProps = passProps
            dataArray.push({
              key: 'TradeDetails',
              link: '/tradeDetails',
              active: true,
            })
            sessionStorage.setItem('breadCrumbData', JSON.stringify(dataArray))
          }
          navigate('/tradeDetails', {
            state: {
              passProps,
            },
          })
        }}
      >
        <SvgEye fillColor="#475569" />
      </span>
    )
  }
  const toggleViewComments = async (select_row: any) => {
    setLoading(true)
    SetToggleComments(!toggleComments)
    setTradeData(select_row)
    setLoading(false)
  }
  const filterOnChange = (e: any) => {
    const { id, checked } = e.target
    FilterOnChange(
      id,
      checkFilter,
      checked,
      setCheckFilter,
      setFilterKey,
      orginalTableData,
      setTableData
    )
  }

  return (
    <RootContainer>
      <StyledPanelHead>
        <StyledPanelHeadTitle>
          Trade Breaks By MPID (RPT 880)
        </StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
       {showFilter && <TradeBreakByMPIDFilter
          input={input}
          reset={reset}
          setLoading={setLoading}
          setInput={setInput}
          assetType={assetType}
          desk={desk}
          entityOption={entityOption}
          entityType={entityType}
          setentityType={setentityType}
          handleSearch={handleSearch}
          refreshData={refreshData}
          setAssetType={setAssetType}
          setDesk={setDesk}
        /> } 
      
        {loading && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <div style={{ marginLeft: '10px' }}>
              <Label> Loading...</Label>
            </div>
          </PopUpStyleLoader>
        )}
        <TableTopBar
          displayFlag={displayFlag}
          refreshData={refreshData}
          downloadExcel={downloadExcel}
          setLoading={setLoading}
          writeExecuteFlag={writeExecuteFlag}
        />
        <StyledTableContainer>
          {tradeData && tradeData.tradeBreaksByMPIDViewModel ? (
            <Table
              hidePagination={false}
              showCancel={false}
              heading={'Trade Breaks By MPID'}
              data={tableData}
              onSelectedRowChange={onSelectedRowChange}
              columns={TableColumns(editItem, toggleViewComments)}
              filterOnChange={filterOnChange}
              checkFilter={checkFilter}
              isFilter="true"
              checkFilterCount={checkFilterCount}
              writeExecuteFlag={writeExecuteFlag}
            />
          ) : null}
        </StyledTableContainer>
        <TableFooter
          setLoading={setLoading}
          refreshData={refreshData}
          displayFlag={displayFlag}
          filterKey={filterKey}
          isSelectedRow={isSelectedRow}
          writeExecuteFlag={writeExecuteFlag}
        />
      </StyledPanelSelect>
      {toggleComments && (
        <CommentTradeByMpid
          tradeRowData={tradeRowData}
          SetToggleComments={SetToggleComments}
          commentView={commentView}
          writeExecuteFlag={writeExecuteFlag}
        />
      )}
    </RootContainer>
  )
}
export default TradeManagementByMPID
