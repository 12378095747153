import { useEffect, useState } from 'react'
import DatePicker from '../../../components/datePicker/DatePicker'
import Dashboard from '../../dashboard/dashboard'
import { DateDashboardWrapper, DateWrapper } from './styles'
import Label from '../../../components/label/Label'
import Button from '../../../components/button/Button'
import { COLORS } from '../../../theme/Colors'
import WeekDayCalender from '../../../components/WeekDayCalander/WeekDayCalender'
import { useSelector } from 'react-redux'
import { FormateDateSelector } from '../../../store/dash-board/about'
import { addDays, formatISO } from 'date-fns'
import { useLocation } from 'react-router-dom'

const CATCAISDashboard = () => {
  const location = useLocation()
  const ExcludeDate = useSelector(FormateDateSelector)
  const [Calendar, setCalender] = useState<boolean>(false)

  const DefaultDate: string =
    localStorage.getItem('DefaultDate') &&
    localStorage.getItem('DefaultDate') != 'undefined'
      ? JSON.parse(localStorage.getItem('DefaultDate') || '')
      : ''
  const [date, setDate] = useState<any>(
    DefaultDate ? new Date(DefaultDate) : ''
  )
  const [SelectedDate, setSelectedDate] = useState<any>(
    DefaultDate ? formatISO(new Date(DefaultDate)).split('T')[0] : ''
  )

  const OnclickSubmit = () => {
    setSelectedDate(formatISO(date).split('T')[0])
  }

  useEffect(() => {
    const paramValues: any = location.state

    if (paramValues.passProps) {
      const passedDate = paramValues.passProps.date ? new Date(paramValues.passProps.date) : date
        setSelectedDate(formatISO(passedDate).split('T')[0])
      setDate(passedDate)
    }
  }, [])

  return (
    <DateDashboardWrapper>
      <DateWrapper>
        <Label margin="5px">SelectDate: </Label>
        <WeekDayCalender
          excludeDates={ExcludeDate}
          open={Calendar}
          onSelect={() => setCalender(false)}
          onFocus={() => setCalender(true)}
          onClickOutside={() => setCalender(false)}
          selected={date}
          maxDate={addDays(new Date(), -1)}
          onChange={(e: string) => {
            setDate(e)
          }}
        />
        <Button
          hoverBgColor={COLORS.Background.Primary70}
          bgColor={COLORS.Background.Primarytext}
          color={COLORS.Background.Primary}
          padding={'4px 16px'}
          borderRadius={'4px'}
          hover={true}
          type={''}
          margin="0px 5px"
          activeBgColor={COLORS.Background.Primaryactive}
          borderColor={'transparent'}
          onClick={OnclickSubmit}
        >
          Submit
        </Button>
      </DateWrapper>
      <Dashboard action={'CATCAIS'} selectedDate={SelectedDate} />
    </DateDashboardWrapper>
  )
}
export default CATCAISDashboard
