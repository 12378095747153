import { useState } from 'react'
import EmailPopUp from '../../notifyEmail/notifyEmail'
import { StyledSelectWrapper, ToastWrapper } from '../../../styles'
import { Toast } from '../../../../../components/toast/Toast'
import {
  ApproveReject,
  NotifyTrade,
  OnSelectChange,
  getSelectedData,
} from '../../../../../helper/tradeRecon/tradeRecon'
import { useDispatch, useSelector } from 'react-redux'
import {
  EmailIdListSelector,
  getEmailList,
} from '../../../../../store/trade-break/trade-break'
import { emailIds } from '../../../../../utils/emailConfig'
import ConfirmationModal from '../../../../../composites/confirmationModal/confirmationModal'
import Button from '../../../../../components/button/Button'
import { Colors } from '../../../../../shared/styles'
import { useNavigate } from 'react-router-dom'

const TableFooter = (props: any) => {
  const {
    isSelectedRow,
    setLoading,
    refreshData,
    displayFlag,
    filterKey,
    writeExecuteFlag,
  } = props
  const emailIdList = useSelector(EmailIdListSelector)
  const dispatch = useDispatch()
  const [notifyResponse, setNotifyResponse] = useState<any>({
    successMessage: '',
    failureMessage: '',
  })
  const [toggleNotifyStatus, setToggleNotifyStatus] = useState<boolean>(false)
  const [notifyModalToggle, setNotifyModalToggle] = useState<boolean>(false)
  const [selectedRows, setSelectedRows] = useState<any>([])
  const [toggleModal, SetToggleModal] = useState<boolean>(false)
  const [approveStatus, setApproveStatus] = useState<any>('All rows updated')
  const navigate = useNavigate()

  const emailOptions: any = [{ value: null, label: '--Select--' }]
  const [email, setEmail] = useState<any>(null)
  emailIds.forEach((email: any) => {
    emailOptions.push({ value: email, label: email })
  })

  const notifyApi = async (selectedIds: any, notes: any) => {
    const selectedTradeArray = getSelectedData()
    setLoading(true)
    setNotifyModalToggle(!notifyModalToggle)
    NotifyTrade(
      selectedTradeArray,
      emailIdList,
      selectedIds,
      notes,
      dispatch
    ).then(async (response: any) => {
      const resonseData = response?.errorResponse
      if (resonseData) {
        resonseData?.message?.length > 0
          ? setNotifyResponse({
              ...notifyResponse,
              failureMessage: resonseData.message,
            })
          : setNotifyResponse({
              ...notifyResponse,
              successMessage: 'Mail sent successfully',
            })
      }
      setToggleNotifyStatus(true)
      refreshData()
      setSelectedRows([])
      setEmail(null)
      setLoading(false)
    })
  }

  const getInitialData = () => {
    SetToggleModal(false)
  }

  const notifyModalOpen = async () => {
    const selectedTradeArray = getSelectedData()
    const notifySelectedRows = selectedTradeArray.filter(
      (obj: any) => obj.selectedCheck
    )

    const exists = Object.create(null)
    const uniqueNotifySelectedRows = notifySelectedRows.filter((o: any) => {
      const key = ['mpid', 'desk'].map((k) => o[k]).join('|')
      if (!exists[key]) {
        exists[key] = true
        return true
      }
    })

    const mpidDeskCollection = uniqueNotifySelectedRows.map((i: any) => {
      return { mpid: i.mpid, desk: i.desk }
    })
    const mpidResponse = await dispatch(getEmailList(mpidDeskCollection))
    setNotifyModalToggle(!notifyModalToggle)
  }

  const approveRejectApi = async (name: string) => {
    const selectedTradeArray = getSelectedData()
    const message =
      name === 'reject'
        ? 'Rejected & Details Saved successfully'
        : 'Approved & Details Saved successfully'
    ApproveReject(selectedTradeArray, name, setLoading, dispatch).then(
      async (resp) => {
        if (resp && resp?.actionCountModel) {
          SetToggleModal(true)
          setApproveStatus(message)
          await refreshData()
          setSelectedRows([])
          setLoading(false)
        } else {
          SetToggleModal(true)
          setApproveStatus(null)
          setSelectedRows([])
          setLoading(false)
        }
      }
    )
  }

  return (
    <>
      {displayFlag &&
        (filterKey === 'APR' ? (
          <StyledSelectWrapper
            style={{ justifyContent: 'flex-end', backgroundColor: 'white' }}
          >
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              disabled={!writeExecuteFlag && !isSelectedRow}
              onClick={() => {
                notifyModalOpen()
              }}
            >
              NOTIFY
            </Button>
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              disabled={!writeExecuteFlag && !isSelectedRow}
              onClick={() => {
                navigate('/tradeRemediation')
              }}
            >
              FIX
            </Button>
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              disabled={!writeExecuteFlag && !isSelectedRow}
              onClick={() => {
                approveRejectApi('reject')
              }}
            >
              REJECT
            </Button>
          </StyledSelectWrapper>
        ) : filterKey === 'NA' ? (
          <StyledSelectWrapper
            style={{ justifyContent: 'flex-end', backgroundColor: 'white' }}
          >
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              disabled={!writeExecuteFlag && !isSelectedRow}
              onClick={() => {
                approveRejectApi('reject')
              }}
            >
              REJECT
            </Button>
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              disabled={!writeExecuteFlag && !isSelectedRow}
              onClick={() => {
                approveRejectApi('approve')
              }}
            >
              APPROVE
            </Button>
          </StyledSelectWrapper>
        ) : filterKey === 'REJ' ? (
          <StyledSelectWrapper
            style={{ justifyContent: 'flex-end', backgroundColor: 'white' }}
          >
            <Button
              hoverBgColor={Colors.White}
              hoverTextColor={Colors.PrimaryText}
              borderColor={Colors.PrimaryText}
              borderRadius={'4px'}
              hover={true}
              padding={'5px 10px'}
              color={Colors.White}
              bgColor={Colors.PrimaryText}
              type={'Button'}
              fontWeight={'400'}
              disabled={!writeExecuteFlag && !isSelectedRow}
              onClick={() => {
                approveRejectApi('approve')
              }}
            >
              APPROVE
            </Button>
          </StyledSelectWrapper>
        ) : null)}
      {notifyModalToggle ? (
        <EmailPopUp
          setNotifyModalToggle={setNotifyModalToggle}
          notifyApi={notifyApi}
        ></EmailPopUp>
      ) : null}
      {toggleModal && (
        <ToastWrapper>
          <Toast
            text={approveStatus}
            type={approveStatus ? 'success' : 'danger'}
            openStatusCallback={() => getInitialData()}
          />
        </ToastWrapper>
      )}
      {toggleNotifyStatus && (
        <ToastWrapper>
          <Toast
            text={
              notifyResponse?.successMessage?.length > 0
                ? notifyResponse?.successMessage
                : notifyResponse?.failureMessage
            }
            type={
              notifyResponse?.successMessage?.length > 0 ? 'success' : 'danger'
            }
            openStatusCallback={() => {
              setToggleNotifyStatus(false)
              setNotifyResponse({
                successMessage: '',
                failureMessage: '',
              })
            }}
          />
        </ToastWrapper>
      )}
    </>
  )
}
export default TableFooter
