/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall, authApiUpload } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  data: []
  totalCount: number
  isLoading: boolean
  successMessage: string
  errorMessage: string
}

const initialState: State = {
  data: [],
  totalCount: 0,
  isLoading: false,
  successMessage: '',
  errorMessage: '',
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'reeorg-Fee-Report-wm',
  initialState,
  reducers: {
    setCurrentData: (state: State, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    setTotalCount: (state: State, action: PayloadAction<any>) => {
      state.totalCount = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
    setErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.errorMessage = action.payload
    },
  },
})

export const {
  setCurrentData,
  setTotalCount,
  setIsLoading,
  setErrorMessage,
  setSuccessMessage,
} = actions

export const getReorgFeeAllCorrespondents: any = async (dispatch: Dispatch) => {
  try {
    const { data, status } = await authApiCall.get(
      'opsstaticdataapi/AssetServicing/GetReorgFeeAllCorrespondents'
    )
    if (status === 200) {
      return data
    }
  } catch (e: any) {
    handleHttpError(dispatch, e, setErrorMessage)
    return null
  }
}
export const getReOrgFeeCorrespondentReport: any =
  (
    correspondentId: string,
    reportRunDate: string,
    pageNumber: number,
    pageSize: number,
    accountNumber: string,
    reportType: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/GetReOrgFeeCorrespondentReport',
        {
          correspondentId,
          reportRunDate,
          pageNumber,
          pageSize,
          accountNumber,
          reportType,
        }
      )
      if (status === 200) {
        dispatch(setIsLoading(false))
        dispatch(setCurrentData(data.data))
        dispatch(setTotalCount(data.totalCount))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setErrorMessage)
      return null
    }
  }

export const getReeOrgFileDownload: any =
  (
    fileName: string,
    Type: string,
    correspondentId: string,
    reportRunDate: string,
    pageNumber: number,
    pageSize: number,
    accountNumber: string,
    reportType: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        `opsstaticdataapi/AssetServicing/GetReOrgFeeCorrespondent${Type}Report`,
        {
          correspondentId,
          reportRunDate,
          pageNumber,
          pageSize,
          accountNumber,
          reportType,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          `${fileName}.${Type == 'CSV' ? 'csv' : 'xlsx'}`
        )
        document.body.appendChild(link)
        link.click()
        dispatch(setIsLoading(false))
        dispatch(setSuccessMessage('Downloaded Successfully'))
        return status
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setErrorMessage)
      dispatch(setIsLoading(false))
      return 400
    }
  }

export const allContact: any = () => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.get(
      'opsstaticdataapi/AssetServicing/GetReOrgFeeReport_AllAddressBookContacts'
    )

    if (status === 200) {
      dispatch(setIsLoading(false))
      return data
    }
  } catch (e: any) {
    handleHttpError(dispatch, e, setErrorMessage)
    dispatch(setIsLoading(false))
    if (e.response.status === 401) return e.response.status
  }
}

export const addContact: any =
  (
    id: number,
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    phone: string,
    notes: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/AddReOrgFeeReport_AddressBookContact',
        {
          firstName,
          lastName,
          company,
          email,
          phone,
          notes,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Added Successfully'))
        dispatch(setIsLoading(false))
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setErrorMessage)
      dispatch(setIsLoading(false))
    }
  }

export const updateContact: any =
  (
    id: number,
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    phone: string,
    notes: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/UpdateReOrgFeeReport_AddressBookContact',
        {
          id,
          firstName,
          lastName,
          company,
          email,
          phone,
          notes,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Updated Successfully'))
        dispatch(setIsLoading(false))
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setErrorMessage)
      dispatch(setIsLoading(false))
    }
  }
export const deleteContact: any =
  (id: number, is_DeActive: boolean) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'opsstaticdataapi/AssetServicing/Update_ReOrgFeeReport_Active_AddressBookContact',
        {
          id,
          is_DeActive: is_DeActive,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Updated Successfully'))
        dispatch(setIsLoading(false))
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setErrorMessage)
      dispatch(setIsLoading(false))
    }
  }
export const getToMail: any = (email: string) => async (dispatch: Dispatch) => {
  try {
    const { data, status } = await authApiCall.get(
      'opsstaticdataapi/AssetServicing/GetReOrgFeeReport_ToEmailContacts',
      {
        params: {
          email,
        },
      }
    )

    if (status === 200) {
      return data
    }
  } catch (e: any) {
    handleHttpError(dispatch, e, setErrorMessage)
  }
}
export const SendMail: any =
  (Subject: string, ToEmail: string, Body: string, Attachments: any) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiUpload.post(
        '/opsstaticdataapi/AssetServicing/ReOrgFeeReport_SendMail',
        {
          Subject,
          ToEmail,
          Body,
          fromEmail: '',
          Attachments,
        }
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Mail Send Successfully'))
        dispatch(setIsLoading(false))
        return true
      }
    } catch (e: any) {
      handleHttpError(dispatch, e, setErrorMessage)
      dispatch(setIsLoading(false))

      return false
    }
  }
export const DataSelector = (store: StoreState) =>
  store.assetService.ReeOrgFeeForWM.data
export const TotalCountSelector = (store: StoreState) =>
  store.assetService.ReeOrgFeeForWM.totalCount
export const LoadingSelector = (store: StoreState) =>
  store.assetService.ReeOrgFeeForWM.isLoading
export const statusSelector = (store: StoreState) =>
  store.assetService.ReeOrgFeeForWM.status
export const SuccessMessageSelector = (store: StoreState) =>
  store.assetService.ReeOrgFeeForWM.successMessage
  export const ErrorMessageSelector = (store: StoreState) =>
  store.assetService.ReeOrgFeeForWM.errorMessage

export default reducer
