import React, { useEffect, useState } from 'react'
import {
  ButtonWrapper,
  DownloadWrapper,
  StyledButtonWrapper,
  StyledContainer,
  StyledRightContainer,
  StyledSelectWrapper,
  StyledTextbox,
} from '../SettlementCommonStyles'
import {
  ProppedDatePicker,
  ProppedLabel,
  ProppedTextbox,
} from '../commonComponents'
import Button from '../../../components/button/Button'
import {
  SettlementShortSalesSelector,
  getBetaShortSaleExcel,
  getSettlementShortSalesDetails,
} from '../../../store/settlementShortSales/settlementShortSales'
import { useDispatch, useSelector } from 'react-redux'
import { IShortSalesReportSearchFilters } from './BetaTradeReportPage'
import { SvgDownloadSmall } from '../../../components/svg/logo/SvgDownload'
import { WDStyledButtonText } from '../../../components/ui/WDTypography'
import { COLORS } from '../../../theme/Colors'
import Label from '../../../components/label/Label'

interface TradeReportSearchPanelProps {
  searchParams: IShortSalesReportSearchFilters
  setsearchParams: React.Dispatch<
    React.SetStateAction<IShortSalesReportSearchFilters>
  >
  setSearchFlag: React.Dispatch<React.SetStateAction<any>>
}

const TradeReportSearchPanel = ({
  searchParams,
  setsearchParams,
  setSearchFlag,
}: TradeReportSearchPanelProps) => {
  const resetSearchFilters = () => {
    setsearchParams({
      fromDate: new Date().toISOString().split('T')[0],
      toDate: new Date().toISOString().split('T')[0],
      symbol: '',
      cusip: '',
      accountNumber: '',
      quantity: '',
      applySearchClick: false,
    })
  }
  const dispatch = useDispatch()
  const SettlementShortSaleData = useSelector(SettlementShortSalesSelector)

  const handleSearch = () => {
    const fetchData = async () => {
      await dispatch(
        getSettlementShortSalesDetails('Beta', {
          ...searchParams,
          symbol: searchParams.symbol === '' ? null : searchParams.symbol,
          cusip: searchParams.cusip === '' ? null : searchParams.cusip,
          accountNumber:
            searchParams.accountNumber === ''
              ? null
              : searchParams.accountNumber,
          quantity: searchParams.quantity === '' ? null : searchParams.quantity,
        })
      )
      setsearchParams({ ...searchParams, applySearchClick: true })
    }
    fetchData()
    setSearchFlag((previousValue: any) => !previousValue)
    setsearchParams({ ...searchParams, applySearchClick: false })
  }

  return (
    <StyledContainer>
      <StyledSelectWrapper>
        <div>
          <ProppedLabel>From Date</ProppedLabel>
          <ProppedDatePicker
            value={searchParams.fromDate}
            onChange={(v: string) => {
              setsearchParams({ ...searchParams, applySearchClick: false })
              setsearchParams((previousValue) => ({
                ...previousValue,
                fromDate: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>To Date</ProppedLabel>
          <ProppedDatePicker
            value={searchParams.toDate}
            onChange={(v: string) => {
              setsearchParams({ ...searchParams, applySearchClick: false })
              setsearchParams((previousValue) => ({
                ...previousValue,
                toDate: v,
              }))
            }}
          />
        </div>
        <div>
          <ProppedLabel>Symbol</ProppedLabel>
          <ProppedTextbox
            value={searchParams.symbol}
            onChange={(v: string) => {
              setsearchParams({ ...searchParams, applySearchClick: false })
              setsearchParams((previousValue) => ({
                ...previousValue,
                symbol: v,
              }))
            }}
            placeHolderText={'SYMBOL'}
          />
        </div>
        <div>
          <ProppedLabel>CUSIP</ProppedLabel>
          <ProppedTextbox
            value={searchParams.cusip}
            onChange={(v: string) => {
              setsearchParams({ ...searchParams, applySearchClick: false })
              setsearchParams((previousValue) => ({
                ...previousValue,
                cusip: v,
              }))
            }}
            placeHolderText={'CUSIP'}
          />
        </div>
        <div>
          <ProppedLabel>Account Number</ProppedLabel>
          <ProppedTextbox
            value={searchParams.accountNumber}
            onChange={(v: string) => {
              setsearchParams({ ...searchParams, applySearchClick: false })
              setsearchParams((previousValue) => ({
                ...previousValue,
                accountNumber: v,
              }))
            }}
            placeHolderText={'ACCOUNT NUMBER'}
          />
        </div>
        <div>
          <ProppedLabel>Quantity</ProppedLabel>
          <ProppedTextbox
            type="number"
            value={searchParams.quantity}
            onChange={(v: string) => {
              setsearchParams({ ...searchParams, applySearchClick: false })
              setsearchParams((previousValue) => ({
                ...previousValue,
                quantity: v,
              }))
            }}
            placeHolderText={'QUANTITY'}
          />
        </div>
        <ButtonWrapper>
          <div>
            <StyledRightContainer>
              <Button
                hoverBgColor={'#A7AFBC'}
                hoverTextColor={'#E2E8F0'}
                borderRadius={'4px'}
                hover={true}
                padding={'5px 10px'}
                bgColor={'#E2E8F0'}
                color={'#A7AFBC'}
                type={'Button'}
                margin={'0px 6px'}
                onClick={() => {
                  resetSearchFilters()
                }}
                borderColor={'#A7AFBC'}
              >
                Reset
              </Button>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                margin={'0px 6px'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledRightContainer>
          </div>
          <DownloadWrapper
            onClick={async () => {
              dispatch(
                getBetaShortSaleExcel(
                  'BetaShortSaleTradeReport',
                  "Details",
                  {
                    ...searchParams,
                    cusip: null,
                    symbol: null,
                    accountNumber: null,
                    quantity: null,
                  }
                )
              )
            }}
          >
            <SvgDownloadSmall
              fillColor={`${COLORS.UI.Primary50}`}
            ></SvgDownloadSmall>
            <WDStyledButtonText>
              <Label>Excel</Label>
            </WDStyledButtonText>
          </DownloadWrapper>
        </ButtonWrapper>
      </StyledSelectWrapper>
    </StyledContainer>
  )
}

export default TradeReportSearchPanel
