
export const OutStandingColumns  =
   [
    {
      Header: 'Source',
      accessor: 'source',
    },
    {
      Header: 'Cusip',
      accessor: 'cusip',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'BuyInQty',
      accessor: 'buyInQty',
    },
    {
      Header: 'AllocationType',
      accessor: 'allocationType',
    },
    {
      Header: 'BuyInNoticeDate',
      accessor: 'buyInNoticeDate',
    },
    {
      Header: 'BuyInExecutionDate',
      accessor: 'buyInExecutionDate',
    },
    {
      Header: 'Counterparty',
      accessor: 'broker',
    },
    {
      Header: 'Comments',
      accessor: 'comments',
    },
  ]

