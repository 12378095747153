import Lottie from 'lottie-react'
import {
  LottieWrapper,
  PopUpStyleLoader,
  RootContainer,
  StyledButtonWrapper,
  StyledFilterPanel,
  StyledPanelHead,
  StyledPanelHeadTitle,
  StyledPanelSelect,
  StyledSelectWrapper,
  StyledTableContainer,
  ToastWrapper,
} from './styles'
import Label from '../../../components/label/Label'
import Loader from '../../../assets/lottiefiles/loader.json'
import SelectDropdown from '../../../components/selectDropdown/SelectDropdown'
import { useEffect, useState } from 'react'
import WeekDayCalender from '../../../components/WeekDayCalander/WeekDayCalender'
import { useDispatch, useSelector } from 'react-redux'
import { FormateDateSelector } from '../../../store/dash-board/about'
import { addDays, formatISO } from 'date-fns'
import Button from '../../../components/button/Button'
import TableColumns from './adminWindowTable/TableColumns'
import {
  BuyInAdminWindowDataSelector,
  HttpErrorMessageSelector,
  HttpSuccessMessageSelector,
  LoadingSelector,
  getAdminWindowData,
  setHttpErrorMessage,
  setSuccessMessage,
} from '../../../store/buyIns/buyInAdminWindow'
import WDCard from '../../../components/ui/WDCard'
import { CardHeader } from '../../../composites/cardHeader/CardHeader'
import { Toast } from '../../../components/toast/Toast'

const BuyInAdminWindow = () => {
  const dispatch = useDispatch()
  const ExcludeDate = useSelector(FormateDateSelector)
  const loading = useSelector(LoadingSelector)
  const adminWindowData = useSelector(BuyInAdminWindowDataSelector)
  const SuccessMessage = useSelector(HttpSuccessMessageSelector)
  const ErrorMessage = useSelector(HttpErrorMessageSelector)
  const [sourceOption, setSourceOption] = useState<any>(null)
  const [status, setStatus] = useState<any>('')
  const [calendar, setCalendar] = useState<boolean>(false)
  const [endCalendar, setEndCalender] = useState<boolean>(false)
  const [tableData, setTableData] = useState<any>([])
  const [gotoFirstPage, setGotoFirstPage] = useState<boolean>(true)
  const DefaultDate: string =
    localStorage.getItem('DefaultDate') &&
    localStorage.getItem('DefaultDate') != 'undefined'
      ? JSON.parse(localStorage.getItem('DefaultDate') || '')
      : ''
  const [jobRunDate, setJobRunDate] = useState<any>(new Date())
  const [jobRunEndDate, setJobRunEndDate] = useState<any>(new Date())
  const sourceValues = [
    {
      value: '',
      label: '--Select--',
    },
    {
      value: 'Beta',
      label: 'BETA',
    },
    {
      value: 'P3',
      label: 'P3',
    },
  ]

  const statusOption = [
    {
      value: '',
      label: 'All',
    },
    {
      value: 'Failed',
      label: 'Failed',
    },
    {
      value: 'Timed-Out',
      label: 'Timed-Out',
    },
    {
      value: 'Removed',
      label: 'Removed',
    },
  ]

  useEffect(() => {
    const setData = async () => {
      await setTableData(adminWindowData?.data?.dtccFileLogViewModelList)
    }
    setData()
  }, [adminWindowData])

  const handleSearch = async () => {
    setGotoFirstPage(!gotoFirstPage)
    const response = await dispatch(
      getAdminWindowData(
        'search',
        sourceOption,
        formatISO(jobRunDate).split('T')[0],
        formatISO(jobRunEndDate).split('T')[0],
        status.trim() === '' ? null : status,
        1,
        10
      )
    )
  }

  return (
    <RootContainer>
      {loading && (
        <PopUpStyleLoader>
          <LottieWrapper>
            <Lottie animationData={Loader} loop={true} />
          </LottieWrapper>
          <div style={{ marginLeft: '10px' }}>
            <Label> Loading...</Label>
          </div>
        </PopUpStyleLoader>
      )}
      <StyledPanelHead>
        <StyledPanelHeadTitle>
          Buy-In Intents Resend Monitor
        </StyledPanelHeadTitle>
      </StyledPanelHead>
      <StyledPanelSelect>
        <StyledFilterPanel>
          <StyledSelectWrapper>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Source
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={sourceValues}
                onChange={(options) => {
                  if (options != '') setSourceOption(options)
                  else setSourceOption(null)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                From Date
                <span style={{ color: 'red' }}>*</span>
              </Label>
              <WeekDayCalender
                onSelect={() => setCalendar(false)}
                excludeDates={ExcludeDate}
                open={calendar}
                onFocus={() => setCalendar(true)}
                onClickOutside={() => setCalendar(false)}
                selected={jobRunDate}
                maxDate={new Date()}
                onChange={(e: Date) => {
                  setJobRunDate(e)
                }}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                To Date
                <span style={{ color: 'red' }}>*</span>
              </Label>
              <WeekDayCalender
                onSelect={() => setEndCalender(false)}
                excludeDates={ExcludeDate}
                open={endCalendar}
                onFocus={() => setEndCalender(true)}
                onClickOutside={() => setEndCalender(false)}
                selected={jobRunEndDate}
                minDate={jobRunDate}
                maxDate={new Date()}
                onChange={(e: Date) => {
                  setJobRunEndDate(e)
                }}
              />
            </div>
            <div>
              <Label
                fontSize="16px"
                fontWeight={400}
                color={'#0F172A'}
                lineHeight={'24px'}
              >
                Status
              </Label>
              <SelectDropdown
                height="32px"
                buttonWidth="150px"
                padding="4px 16px"
                borderRadius="4px"
                fw={100}
                lh="24px"
                fs="16px"
                options={statusOption}
                onChange={(options) => {
                  setStatus(options)
                }}
                defaultValue={{
                  value: '',
                  label: '--Select--',
                }}
              />{' '}
            </div>
            <StyledButtonWrapper>
              <Button
                hoverBgColor={'#FFFFFF'}
                hoverTextColor={'#2563EB'}
                borderRadius={'4px'}
                hover={true}
                padding={'4px 16px'}
                bgColor={'#2563EB'}
                color={'White'}
                type={'Button'}
                onClick={() => {
                  handleSearch()
                }}
                borderColor={'#2563EB'}
              >
                Search
              </Button>
            </StyledButtonWrapper>
          </StyledSelectWrapper>
        </StyledFilterPanel>
        <StyledTableContainer>
          <WDCard>
            <CardHeader
              label={'Buy-In Intents Admin Window'}
              icon={false}
              amountValue={`Total: ${
                adminWindowData?.data?.dtccFileLogCount
                  ? adminWindowData?.data?.dtccFileLogCount
                  : 0
              }`}
            />
          </WDCard>
          <TableColumns
            data={tableData && tableData.length ? tableData : []}
            fromDate={formatISO(jobRunDate).split('T')[0]}
            toDate={formatISO(jobRunEndDate).split('T')[0]}
            sourceOption={sourceOption}
            status={status}
            totalRecords={
              adminWindowData?.data?.dtccFileLogCount
                ? adminWindowData?.data?.dtccFileLogCount
                : 0
            }
            gotoFirstPage={gotoFirstPage}
          />
        </StyledTableContainer>
      </StyledPanelSelect>
      {(SuccessMessage?.length > 0 || ErrorMessage?.length > 0) && (
        <ToastWrapper>
          <Toast
            text={SuccessMessage || ErrorMessage}
            type={SuccessMessage?.length > 0 ? 'success' : 'danger'}
            openStatusCallback={() => {
              dispatch(setHttpErrorMessage(''))
              dispatch(setSuccessMessage(''))
            }}
          />
        </ToastWrapper>
      )}
    </RootContainer>
  )
}

export default BuyInAdminWindow
