import Button from '../../../../components/button/Button'
import { Colors } from '../../../../shared/styles'
import {
  formattingCurrencyWithDecimal,
  formattingDate,
  formattingNumber,
} from '../../../../utils/globalFunctions'

const TableColumns = (editItem: any, toggleViewComments: any) => {
  const columns = [
    {
      Header:'Workflow Status',
      accessor: 'workFlowStatus',
      
    },
    {
      Header: 'Report Date',
      accessor: (d: any) => {
        return formattingDate(d.forDate)
      },
    },
    {
      Header: 'TradeDate',
      accessor: (d: any) => {
        return formattingDate(d.tradeDate)
      },
    },
    {
      Header: 'SettleDate',
      accessor: (d: any) => {
        return formattingDate(d.settleDate)
      },
    },
    {
      Header: 'EC',
      accessor: 'entityCode',
    },
    {
      Header: 'MPID',
      accessor: 'mpid',
    },
    {
      Header: 'Desk',
      accessor: 'desk',
    },
    {
      Header: 'Buy/Sell',
      accessor: 'buySell',
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'Description',
      accessor: 'name',
    },
    {
      Header: 'CUSIP',
      accessor: 'cusip',
    },
    {
      Header: 'House Quantity',
      accessor: (d: any) => {
        return d.houseQuantity ? formattingNumber(d.houseQuantity) : null
      },
    },
    {
      Header: 'House Amount',
      accessor: (d: any) => {
        if (d.houseAmount) return formattingCurrencyWithDecimal(d.houseAmount)
      },
    },
    {
      Header: 'Street Quantity',
      accessor: (d: any) => {
        return d.streetQuantity ? formattingNumber(d.streetQuantity) : null
      },
    },
    {
      Header: 'Street Amount',
      className: 'nmbr',
      accessor: (d: any) => {
        if (d.streetAmount) return formattingCurrencyWithDecimal(d.streetAmount)
      },
    },
    {
      Header: 'NetBreak Quantity',
      accessor: (d: any) => {
        if (d.netBreakQuantity) return formattingNumber(d.netBreakQuantity)
      },
    },
    {
      Header: 'NetBreak Amount',
      accessor: (d: any) => {
        if (d.netBreakAmount)
          return formattingCurrencyWithDecimal(d.netBreakAmount)
      },
    },
    {
      Header: 'View Details',
      Cell: editItem,
    },
    {
      Header: 'Comments/Activity',
      Cell: (props: any) => {
        return (
          <>
            <div>
              <p>
                <Button
                  hoverTextColor={Colors.White}
                  hoverBgColor={Colors.PrimaryText}
                  borderColor={Colors.PrimaryText}
                  borderRadius={'8px'}
                  hover={true}
                  padding={'3px 38px 3px 38px'}
                  bgColor={Colors.White}
                  color={Colors.PrimaryText}
                  type={'Button'}
                  fontWeight={'400'}
                  onClick={() => toggleViewComments(props.cell.row.original)}
                >
                  View
                </Button>
              </p>
            </div>
          </>
        )
      },
    },
  ]
  return columns
}
export default TableColumns
