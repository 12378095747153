/* eslint-disable @typescript-eslint/no-explicit-any */
import { StoreState } from '../index'
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { authApiCall } from '../../utils/api'
import { handleHttpError } from '../../utils/globalFunctions'

interface State {
  action: string
  isLoading: boolean
  data: any
  recordDetailsData: any
  httpErrorMessage: string
  successMessage: string
}

const initialState: State = {
  action: 'search',
  isLoading: false,
  data: [],
  recordDetailsData: [],
  httpErrorMessage: '',
  successMessage: '',
}

// TODO: Update PayloadAction Type
const { actions, reducer }: Slice = createSlice({
  name: 'buyInAdminWindow',
  initialState,
  reducers: {
    setAdminWindowData: (state: State, action: PayloadAction<any>) => {
      state.data = action.payload
    },
    setRecordDetailsData: (state: State, action: PayloadAction<any>) => {
      state.recordDetailsData = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
    setHttpErrorMessage: (state: State, action: PayloadAction<string>) => {
      state.httpErrorMessage = action.payload
    },
    setSuccessMessage: (state: State, action: PayloadAction<string>) => {
      state.successMessage = action.payload
    },
  },
})

export const {
  setAdminWindowData,
  setRecordDetailsData,
  setIsLoading,
  setAction,
  setHttpErrorMessage,
  setSuccessMessage,
} = actions

export const getAdminWindowData: any =
  (
    action: string,
    source: string,
    fromDate: string,
    toDate: string,
    DTCCFileLogStatus: string,
    page: number,
    pageSize: number
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'opsfailprocessapi/BuyInIntents/GetDTCCFileLogDetails',
        {
          source,
          fromDate,
          toDate,
          DTCCFileLogStatus,
          page,
          pageSize,
        }
      )
      if (status === 200) {
        dispatch(setAction(action))
        dispatch(
          setAdminWindowData({
            data: data,
          })
        )

        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      dispatch(
        setAdminWindowData({
          data: [],
        })
      )
      return null
    }
  }

export const getWindowRecordDetails: any =
  (id: number, page: number, pageSize: number) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.get(
        `opsfailprocessapi/BuyInIntents/GetBuyInReceiveFailReport?dtccFileLogId=${id}&page=${page}&pageSize=${pageSize}`
      )
      if (status === 200) {
        dispatch(
          setRecordDetailsData({
            data: data,
          })
        )

        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      dispatch(
        setRecordDetailsData({
          data: [],
        })
      )
      return null
    }
  }

export const deleteWindowRecordDetails: any =
  (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.delete(
        `opsfailprocessapi/BuyInIntents/DeleteBuyInReceiveFailReport?dtccFileLogId=${id}`
      )
      if (status === 200) {
        dispatch(setSuccessMessage('Records Deleted Successfully'))
        dispatch(setIsLoading(false))
        return 200
      }
    } catch (e: any) {
      dispatch(setIsLoading(false))
      handleHttpError(dispatch, e, setHttpErrorMessage)
      dispatch(setIsLoading(false))
      return null
    }
  }

export const BuyInAdminWindowDataSelector = (store: StoreState) =>
  store.BuyIns.buyInAdminWindow.data
export const RecordDetailsDataSelector = (store: StoreState) =>
  store.BuyIns.buyInAdminWindow.recordDetailsData
export const actionSelector = (store: StoreState) =>
  store.BuyIns.buyInAdminWindow.action
export const LoadingSelector = (store: StoreState) =>
  store.BuyIns.buyInAdminWindow.isLoading
export const HttpErrorMessageSelector = (store: StoreState) =>
  store.BuyIns.buyInAdminWindow.httpErrorMessage
export const HttpSuccessMessageSelector = (store: StoreState) =>
  store.BuyIns.buyInAdminWindow.successMessage
export default reducer
