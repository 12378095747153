import React, { useEffect } from 'react'
import { StyledTableContainer } from '../SettlementCommonStyles'
import Table from '../table/Table'
import { shortSalesSummaryColumns } from './shortSalesColumns'
import { TabListProps } from '../../../components/tab/TabListModels'
import { useDispatch, useSelector } from 'react-redux'
import {
  SettlementShortSummarySelector,
  ShortSalesTotalCountSummarySelector,
  getSettlementShortSalesSummary,
} from '../../../store/settlementShortSales/settlementShortSales'

const TradeReportSummary = ({
  commonTabProps,
  setCommonTabProps,
}: TabListProps) => {
  const dispatch = useDispatch()
  const data = useSelector(SettlementShortSummarySelector)
  const totalCount = useSelector(ShortSalesTotalCountSummarySelector)
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        getSettlementShortSalesSummary('Beta', {
          ...commonTabProps,
          symbol: commonTabProps.symbol === '' ? null : commonTabProps.symbol,
          cusip: commonTabProps.cusip === '' ? null : commonTabProps.cusip,
          accountNumber:
            commonTabProps.accountNumber === ''
              ? null
              : commonTabProps.accountNumber,
          quantity:
            commonTabProps.quantity === '' ? null : commonTabProps.quantity,
        })
      )
    }
    commonTabProps.applySearchClick && fetchData()

    setCommonTabProps &&
      setCommonTabProps({ ...commonTabProps, tabName: 'Summary' })
  }, [])

  return (
    <Table
      hidePagination={false}
      heading={'Short Sales Summary'}
      data={data?.length ? data : []}
      totalCount={totalCount ? totalCount.data : 0}
      columns={shortSalesSummaryColumns()}
    />
  )
}

export default TradeReportSummary
