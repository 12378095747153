import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../../../components/button/Button'
import DatePicker from '../../../../../components/datePicker/DatePicker'
import { InfoLabel } from '../../../../../components/infoLabel/InfoLabel'
import Label from '../../../../../components/label/Label'
import SelectDropdown from '../../../../../components/selectDropdown/SelectDropdown'
import SelectDropdownWithCheckBox from '../../../../../components/selectDropdownWithCheckbox/SelectDropdown'
import { Textbox } from '../../../../../components/textbox/Textbox'
import { formattingDate } from '../../../../../utils/globalFunctions'
import { InfoTile, StyledButtonWrapper } from '../../../styles'
import { StyledFilterPanel, StyledSelectWrapper } from '../../styles'
import { useLocation } from 'react-router-dom'
import {
  DeskSelector,
  SetTradeManagement,
  assetSelector,
  getTradeBreak,
  setAction,
} from '../../../../../store/trade-management/trade-management'
import { useEffect, useState } from 'react'

const TradeBreakByMPIDFilter = (FilterProps: any) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const asset = useSelector(assetSelector)
  const Desk = useSelector(DeskSelector)
  const TodayDate = new Date();
  TodayDate.setDate(TodayDate.getDate() - 1);
const priorDate = new Date().setDate(TodayDate.getDate() - 30)
const [deskOptions] = useState<any>([
  { value: '', label: '--Select--' },
  ...Desk.map((a:string) =>{
    return { "value": a, "label": a }
  } ),
])

  const [assetOption, setAssetOption] = useState<any>([
    { value: '', label: '--Select--' },
  ])
  useEffect(() => {
    const assetDropdownValues = [{ value: '', label: '--Select--' }]
    if (asset && asset.length) {
      asset.forEach((element: any, index: any) => {
        assetDropdownValues.push({
          value: element.assettype,
          label: element.assettype,
        })
      })
      setAssetOption(assetDropdownValues)
    }
  }, [asset])
  const {
    input,
    setInput,
    reset,
    setLoading,
    assetType,
    setAssetType,
    handleSearch,
    setentityType,
    entityOption,
    entityType,
    refreshData,
    desk,
    setDesk,
  } = FilterProps
  const resetFilterData = async () => {
    setLoading(true)
    dispatch(setAction(''))
    dispatch(SetTradeManagement(''))
    const paramValues: any = location.state
    const tradeDataBySymbol = await dispatch(
      getTradeBreak(
        'search',
        TodayDate.toISOString().split('T')[0],
        TodayDate.toISOString().split('T')[0],
        null,
        null,
        null,
        null,
        null,
        null,
        null
      )
    )
    reset(tradeDataBySymbol, paramValues)
  }

  return (
    <StyledFilterPanel>
      <StyledSelectWrapper>
        <div>
          <Label
            fontSize="16px"
            fontWeight={400}
            color={'#0F172A'}
            lineHeight={'24px'}
          >
            From Date
          </Label>
          <DatePicker
            onChange={(e: any) => {
              setInput({ ...input, startDate: e })
            }}
            value={input.startDate}    
            min={new Date(priorDate).toISOString().split('T')[0]}
            max={TodayDate.toISOString().split('T')[0]}
            bgColor=""
            borderColor=""
            placeHolder="From"
            padding={'4px 8px'}
            maxWidth="180px"
            defaultValue={TodayDate.toISOString().split('T')[0]}
          />
        </div>
        <div>
          <Label
            fontSize="16px"
            fontWeight={400}
            color={'#0F172A'}
            lineHeight={'24px'}
          >
            To Date
          </Label>
          <DatePicker
            onChange={(e: any) => {
              setInput({ ...input, endDate: e })
            }}
            max={TodayDate.toISOString().split('T')[0]}
            min={input.startDate}
            bgColor=""
            borderColor=""
            placeHolder="From"
            padding={'4px 8px'}
            maxWidth="180px"
            value={input.endDate}
            defaultValue={TodayDate.toISOString().split('T')[0]}
          />
        </div>
        <div>
          <Label
            fontSize="16px"
            fontWeight={400}
            color={'#0F172A'}
            lineHeight={'24px'}
          >
            Asset Type
          </Label>
          <SelectDropdown
            height="32px"
            buttonWidth="204px"
            padding="4px 16px"
            borderRadius="4px"
            fw={100}
            lh="24px"
            fs="16px"
            options={assetOption}
            value={assetType}
            onChange={(options) => {
              setAssetType({ value: options, label: options })
            }}
            defaultValue={{
              value: '',
              label: '--Select--',
            }}
          />{' '}
        </div>
        <div>
          <Label
            fontSize="16px"
            fontWeight={400}
            color={'#0F172A'}
            lineHeight={'24px'}
          >
            Entity Code
          </Label>
          <SelectDropdownWithCheckBox
            height="32px"
            isMulti={true}
            buttonWidth="300px"
            padding="4px 16px"
            borderRadius="4px"
            fw={100}
            lh="24px"
            fs="16px"
            options={entityOption}
            value={entityType}
            defaultValue={{
              value: '',
              label: '--Select--',
            }}
            optionValues={entityOption}
            onChange={(options: any) => {
              setentityType(options)
            }}
            allowSelectAll={true}
            whiteSpace={'nowrap'}
            overflow={'hidden'}
            flexWrap={'nowrap'}
            labelValue={'EntityCodes'}
          />{' '}
        </div>
        <div>
          <Label
            fontSize="16px"
            fontWeight={400}
            color={'#0F172A'}
            lineHeight={'24px'}
          >
            MPID
          </Label>
          <Textbox
            onChange={(e: any) => {
              setInput({ ...input, mpid: e })
              if (e?.toLowerCase()?.trim() != 'wsgd') {
                setDesk({ value: '', label: '' })
              }
            }}
            placeholder={'MPID'}
            value={input.mpid}
            style={{
              borderRadius: '4px',
              height: '32px',
              padding: '4px 8px',
            }}
          />
        </div>
        <div>
          <Label
            fontSize="16px"
            fontWeight={400}
            color={'#0F172A'}
            lineHeight={'24px'}
          >
            Desk
          </Label>
          <SelectDropdown
            isDisabled={input?.mpid?.toLowerCase()?.trim() != 'wsgd'}
            height="32px"
            buttonWidth="204px"
            padding="4px 16px"
            borderRadius="4px"
            fw={100}
            lh="24px"
            fs="16px"
            options={deskOptions}
            value={
              input?.mpid?.toLowerCase()?.trim() === 'wsgd'
                ? desk
                : { value: '', label: '--Select--' }
            }
            onChange={(options) => {
              setDesk({ value: options, label: options })
            }}
            defaultValue={{
              value: '',
              label: '--Select--',
            }}
          />{' '}
        </div>
        <div>
          <Label
            fontSize="16px"
            fontWeight={400}
            color={'#0F172A'}
            lineHeight={'24px'}
          >
            Symbol
          </Label>
          <Textbox
            style={{
              borderRadius: '4px',
              height: '32px',
              padding: '4px 8px',
            }}
            onChange={(e: any) => {
              setInput({ ...input, symbol: e })
            }}
            placeholder={'SYMBOL'}
            value={input.symbol}
          />
        </div>

        <div>
          <Label
            fontSize="16px"
            fontWeight={400}
            color={'#0F172A'}
            lineHeight={'24px'}
          >
            CUSIP
          </Label>
          <Textbox
            style={{
              borderRadius: '4px',
              height: '32px',
              padding: '4px 8px',
            }}
            onChange={(e: any) => {
              setInput({ ...input, cusip: e })
            }}
            placeholder={'CUSIP'}
            value={input.cusip}
          />
        </div>
        {input.settleDate ? (
          <InfoTile>
            <InfoLabel
              title={`Settle Date :${formattingDate(input.settleDate)}`}
              onCloseLabel={async () => {
                await setInput({
                  ...input,
                  settleDate: '',
                })
                await refreshData()
              }}
            />
          </InfoTile>
        ) : null}
        <StyledButtonWrapper>
          <Button
            hoverBgColor={'#A7AFBC'}
            hoverTextColor={'#E2E8F0'}
            borderRadius={'4px'}
            hover={true}
            padding={'5px 10px'}
            bgColor={'#E2E8F0'}
            color={'#A7AFBC'}
            type={'Button'}
            margin={'0px 6px'}
            onClick={() => {
              resetFilterData()
            }}
            borderColor={'#A7AFBC'}
          >
            Reset
          </Button>
          <Button
            hoverBgColor={'#FFFFFF'}
            hoverTextColor={'#2563EB'}
            borderRadius={'4px'}
            hover={true}
            padding={'4px 16px'}
            bgColor={'#2563EB'}
            color={'White'}
            type={'Button'}
            margin={'0px 6px'}
            onClick={() => {
              handleSearch()
            }}
            borderColor={'#2563EB'}
          >
            Search
          </Button>
        </StyledButtonWrapper>
      </StyledSelectWrapper>
    </StyledFilterPanel>
  )
}
export default TradeBreakByMPIDFilter
