import { ActionIconContainer, TableContainer } from './styles'
import AdminWindowTable from './AdminWindowTable'
import { SvgEye } from '../../../../components/svg/SvgEye'
import { Colors } from '../../../../shared/styles'
import { useDispatch, useSelector } from 'react-redux'
import {
  RecordDetailsDataSelector,
  deleteWindowRecordDetails,
  getWindowRecordDetails,
} from '../../../../store/buyIns/buyInAdminWindow'
import { useState } from 'react'
import AdminWindowRecordDetails from './AdminWindowRecordDetails'
import { SvgDelete } from '../../../../components/svg/SvgDelete'

const TableColumns = (props: any) => {
  const dispatch = useDispatch()
  const recordDetailsData = useSelector(RecordDetailsDataSelector)
  const [openRecordsDetails, setOpenRecordsDetails] = useState<boolean>(false)
  const [selectedRowId, setSelectedRowId] = useState<number>()
  const [gotoFirstPage, setGotoFirstPage] = useState<boolean>(true)

  const viewDetails = async (select_row: any) => {
    setGotoFirstPage(!gotoFirstPage)
    setSelectedRowId(select_row)
    const response = await dispatch(
      getWindowRecordDetails(select_row?.id, 1, 10)
    )
    setOpenRecordsDetails(true)
  }
  const deleteRecordsDetails = async (select_row: any) => {
    const response = await dispatch(deleteWindowRecordDetails(select_row?.id))
  }

  const tableColumns = [
    {
      Header: 'DTCCFunction',
      accessor: 'dtccFunction',
    },
    {
      Header: 'ParticipantId',
      accessor: 'participantId',
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'DTCCPutFileName',
      accessor: 'dtccputFileName',
    },
    {
      Header: 'DTCCGetFileName',
      accessor: 'dtccgetFileName',
    },
    {
      Header: 'ModifiedBy',
      accessor: 'modifiedBy',
    },
    {
      Header: 'ModifiedOn',
      accessor: 'modifiedOn',
    },
    {
      Header: 'CreatedBy',
      accessor: 'createdBy',
    },
    {
      Header: 'CreatedOn',
      accessor: 'createdOn',
    },
    {
      Header: 'Action',
      Cell: (table: { cell: { row: { index: number; original: any } } }) => {
        return (
          <ActionIconContainer>
            <span
              role="presentation"
              title="View"
              style={{ cursor: 'pointer', width: '25px' }}
              onClick={() => {
                viewDetails(table.cell?.row?.original)
              }}
            >
              <SvgEye fillColor={Colors.Black} />
            </span>
            <span
              role="presentation"
              title="Delete"
              style={{ cursor: 'pointer', width: '25px' }}
              onClick={() => {
                deleteRecordsDetails(table.cell?.row?.original)
              }}
            >
              <SvgDelete fillColor="#475569" />
            </span>
          </ActionIconContainer>
        )
      },
    },
  ]

  return (
    <TableContainer>
      <AdminWindowTable
        columns={tableColumns}
        data={props.data}
        fromDate={props.fromDate}
        toDate={props.toDate}
        sourceOption={props.sourceOption}
        status={props.status}
        totalRecords={props.totalRecords}
        gotoFirstPage={props.gotoFirstPage}
      />
      {openRecordsDetails && (
        <AdminWindowRecordDetails
          data={recordDetailsData?.data?.generateDTCCReportModelList}
          selectedRowId={selectedRowId}
          totalRecords={recordDetailsData?.data?.buyInReceiveFaildataCount}
          gotoFirstPage={props.gotoFirstPage}
          setOpenRecordsDetails={setOpenRecordsDetails}
        />
      )}
    </TableContainer>
  )
}

export default TableColumns
