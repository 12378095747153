import React from 'react'
import Label from '../../../label/Label'
import { BodyContainer, DropDownContainer } from './styles'

const MultipleDropDown = ({ dropDowns }: any) => {
  return (
    <DropDownContainer>
      {dropDowns.map((item: any, index: number) => {
        const { attribute, onChange, options, element, label } = item
        return (
          <>
            <Label lineHeight="20px">{label}: </Label>
            <BodyContainer key={index}>
              {React.createElement(
                element,
                {
                  type: item.type,
                  onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    onChange(e, attribute)
                  },
                  style: { width: '100%' },
                },
                options &&
                  options.map((option: string) =>
                    React.createElement('option', { option }, option)
                  )
              )}
            </BodyContainer>
          </>
        )
      })}
    </DropDownContainer>
  )
}

export default MultipleDropDown
