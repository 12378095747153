import Label from '../../../../components/label/Label'
import Loader from "../../../../assets/lottiefiles/loader.json";
import {
  WDStyledButtonText,
  WDStyledMainHeading,
} from '../../../../components/ui/WDTypography'
import {
  CancelEmailModal,
  CancelSendButtonWrapper,
  DeleteContactPopup,
  EmailAddressTable,
  EmailHeaderContainer,
  EmailModalWrapper,
  ToastWrapper,
} from '../styles'
import 'react-quill/dist/quill.snow.css'
import { useEffect, useState } from 'react'
import Button from '../../../../components/button/Button'
import { COLORS } from '../../../../theme/Colors'
import { useDispatch, useSelector } from 'react-redux'
import Table from '../../../../components/table/Table'
import { Toast } from '../../../../components/toast/Toast'
import { StyledImage } from '../../../../components/image/styles'
import { Icons } from '../../../../shared/GlobalStyle'
import ConfirmDeletePopUp from '../../../../components/popUp/confirmDeletePopUp/ConfirmPopUp'
import AddPopUp from '../../../../components/popUp/addPopUp/AddEditPopUp'
import { ValidateUserMPIDSchema } from './AddContactMPIDValidation'
import { z } from 'zod'
import {
  addContactMpid,
  deleteContactMpid,
  GetContactMpid,
  updateContactMpid,
  ResponseSelector,
  statusSelector,
  loadingSelector,
  GetMPIDList,
  MPIDSelector,
  DeskSelector,
} from '../../../../store/trade-management/trade-management'
import Lottie from 'lottie-react'
import { LottieWrapper, PopUpStyleLoader } from '../../styles'
import { TableCellProps, TableInstance } from 'react-table';

export interface Props {
  PopUpClose: () => void
}

const AddContactMpid = ({ PopUpClose }: Props) => {
  const dispatch = useDispatch()
  const MPID = useSelector(MPIDSelector)
  const Desk = useSelector(DeskSelector)
  const response = useSelector(ResponseSelector)
  const status = useSelector(statusSelector)
  const loading = useSelector(loadingSelector)
  const [toastPopup, setToastPopup] = useState<boolean>(false)
  const [toggleEdit, SetToggleEdit] = useState<boolean>(false)
  const [toggleInsert, SetToggleInsert] = useState<boolean>(false)
  const [showTable, SetShowTable] = useState<boolean>(false)
  const [toggleDeactivate, SetToggleDeactivate] = useState<boolean>(false)
  const [data, setData] = useState<any>([{}])
  const [validationError, setValidationError] = useState< z.ZodIssue[]>([])
  const [user, setUser] = useState({
    id: 0,
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    notes: '',
    mPID: '',
    desk:'',
    Active: true,
  })
  const InputTypeList = [
    {
      label: 'First Name',
      type: 'text',
      inputValue: user.firstName,
      attribute: 'firstName',
      element: 'input',
    },
    {
      label: 'Last Name',
      type: 'text',
      inputValue: user.lastName,
      attribute: 'lastName',
      element: 'input',
    },
    {
      label: 'Company',
      type: 'text',
      inputValue: user.company,
      attribute: 'company',
      element: 'input',
    },
    {
      label: 'Email',
      type: 'email',
      inputValue: user.email,
      attribute: 'email',
      element: 'input',
    },
    {
      label: ' MPID',
      attribute: 'mPID',
      element:"select",
      options:["select",...MPID.map((a:{id:number,mpid:string}) => a.mpid)]
    },
    {
      label: ' Desk',
      attribute: 'desk',
      element:"select",
      options:["select",...Desk.map((a:string) => a)],
      disabled:user.mPID!=="WSGD"
    },
    {
      label: 'Phone',
      type: 'tel',
      inputValue: user.phone,
      attribute: 'phone',
      element: 'input',
      placeHolder:"(xxx) xxx-xxxx"
    },
    {
      label: 'Is Active?',
      type: 'checkbox',
      inputValue: user.Active,
      attribute: 'Active',
      element: 'input',
      checked: user.Active,
    },
    {
      label: 'Notes',
      inputValue: user.notes,
      attribute: 'notes',
      element: 'textarea',
      columnWidth:'100%'
    },
  ]
  const addMapping = () => {
    setUser({
      id: 0,
      firstName: '',
      lastName: '',
      company: '',
      email: '',
      phone: '',
      notes: '',
      mPID: '',
      desk:'',
      Active: true,
    })
    SetToggleInsert(true)
  }
  
  useEffect(() => {
    async function GetAddress() {
      await dispatch(GetMPIDList())
      const response = await dispatch(GetContactMpid())
      setData(response?.tradeReconAddressBookList)
      SetShowTable(true)
    }

    GetAddress()
  }, [])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>, attribute: string) => {
    e.target.type === 'checkbox'
      ? setUser({ ...user, [attribute]: e.target.checked })
      : setUser({ ...user, [attribute]: e.target.value })
  }
  

  const addNew = async () => {
    try {
      const validatedForm = ValidateUserMPIDSchema.parse(user)
      SetToggleInsert(false)
      await dispatch(
        addContactMpid(
          user.id,
          user.firstName,
          user.lastName,
          user.company,
          user.email,
          user.phone,
          user.notes ? user.notes : '-',
          user.mPID,
          user.desk,
          user.Active ? 'Y' : 'N'
        )
      )
      const response = await dispatch(GetContactMpid())
      setData(response?.tradeReconAddressBookList)
      await setToastPopup(true)
    } catch (err) {
      if (err instanceof z.ZodError) {
        const errors = err.issues
        if (errors) {
          setValidationError(errors)
        }
      }
    }
  }

  const update = async () => {
    try{
      const validatedForm = ValidateUserMPIDSchema.parse(user)
    SetToggleEdit(false)
    await dispatch(
      updateContactMpid(
        user.id,
        user.firstName,
        user.lastName,
        user.company,
        user.email,
        user.phone,
        user.notes ? user.notes : '  ',
        user.mPID,
        user.desk,
        user.Active ? 'Y' : 'N'
      )
    )
    const response = await dispatch(GetContactMpid())
    setData(response?.tradeReconAddressBookList)
    await setToastPopup(true)
      }
    catch (err) {
      if (err instanceof z.ZodError) {
        const errors = err.issues
        if (errors) {
          setValidationError(errors)
        }
      }
  }}
  const deactive = async () => {
    SetToggleDeactivate(false)
    await dispatch(deleteContactMpid(user.id))
    const response = await dispatch(GetContactMpid())
    setData(response?.tradeReconAddressBookList)
    await setToastPopup(true)
  }

  return (
    <EmailModalWrapper>
      <EmailHeaderContainer>
        <WDStyledMainHeading>Contacts</WDStyledMainHeading>
        <CancelSendButtonWrapper>
          <CancelEmailModal
            onClick={() => {
              PopUpClose()
            
            }}
          >
            <WDStyledButtonText>
              <Label>Cancel</Label>
            </WDStyledButtonText>
          </CancelEmailModal>
          <Button
            hoverBgColor={COLORS.Background.Primary70}
            bgColor={COLORS.Background.Primarytext}
            color={COLORS.Background.Primary}
            padding={'4px 16px'}
            borderRadius={'4px'}
            hover={true}
            gap={'8px'}
            type={''}
            activeBgColor={COLORS.Background.Primaryactive}
            borderColor={'transparent'}
            onClick={() => {
              addMapping()
            }}
          >
            Add New
          </Button>
        </CancelSendButtonWrapper>
      </EmailHeaderContainer>
      <EmailAddressTable>
        {showTable && (
          <Table
            tableColumns={[
              {
                Header: 'First Name',
                accessor: 'firstName',
              },

              {
                Header: 'Last Name',
                accessor: 'lastName',
              },
              {
                Header: 'Company',
                accessor: 'company',
              },
              {
                Header: 'Email',
                accessor: 'email',
              },
              {
                Header: 'Phone',
                accessor: 'phone',
              },
              {
                Header: 'Notes',
                accessor: 'notes',
              },
              {
                Header: 'MPID',
                accessor: 'mpid',
              },
              {
                Header: 'Desk',
                accessor: 'desk',
              },
              {
                Header: 'Active',
                accessor: 'activeYN',
              },
              {
                Header: 'Delete',
                Cell: (e: TableInstance) => {
                  return (
                    <StyledImage
                      style={{ cursor: 'pointer' }}
                      title="delete"
                      src={Icons.Delete}
                      onClick={() => {
                        setUser({
                          id: data[e.cell.row.id].id,
                          firstName: data[e.cell.row.id].firstName,
                          lastName: data[e.cell.row.id].lastName,
                          company: data[e.cell.row.id].company,
                          email: data[e.cell.row.id].email,
                          phone: data[e.cell.row.id].phone,
                          notes: data[e.cell.row.id].notes,
                          mPID: data[e.cell.row.id].mpid,
                          desk:data[e.cell.row.id].desk ?? null,
                          Active: data[e.cell.row.id].activeYN,
                        })
                        SetToggleDeactivate(true)
                      }}
                    ></StyledImage>
                  )
                },
              },
              {
                Header: 'Edit',
                Cell: (e: TableInstance) => {
                  return (
                    <StyledImage
                      title="Edit"
                      onClick={() => {
                        setUser({
                          id: data[e.cell.row.id].id,
                          firstName: data[e.cell.row.id].firstName,
                          lastName: data[e.cell.row.id].lastName,
                          company: data[e.cell.row.id].company,
                          email: data[e.cell.row.id].email,
                          phone: data[e.cell.row.id].phone,
                          notes: data[e.cell.row.id].notes,
                          mPID: data[e.cell.row.id].mpid,
                          desk:data[e.cell.row.id].desk ?? null,
                          Active: data[e.cell.row.id].activeYN == "Y" ? true :false,
                        })

                        SetToggleEdit(true)
                      }}
                      width={'25px'}
                      style={{ cursor: 'pointer' }}
                      src={Icons.Edit}
                      alt=""
                    />
                  )
                },
              },
            ]}
            tableData={data}
            onClick={() => {
              //
            }}
          />
        )}
      </EmailAddressTable>

      {/*Insert*/}
      {toggleInsert && (
          <AddPopUp
          maxContainerHeight='500px'
          maxContainerWidth='700px'
            title={'Add Contact'}
            buttonLabel={'Add'}
            onCancelClick={() => {
              SetToggleInsert(false)
              setValidationError([])
            }}
            onButtonClick={addNew}
            listItems={InputTypeList}
            onChange={onChange}
            error={validationError}
          />
      )}

      {/*Edit*/}
      {toggleEdit && (
          <AddPopUp
          maxContainerHeight='40%'
          maxContainerWidth='70%'
            title={'Update Contact'}
            buttonLabel={'Update'}
            onCancelClick={() => {
              SetToggleEdit(false)
            }}
            onButtonClick={update}
            listItems={InputTypeList}
            onChange={onChange}
            error={validationError}
          />
      )}

      {/*Delete*/}
      {toggleDeactivate && (
        <DeleteContactPopup>
          <ConfirmDeletePopUp
            title={'Delete contact'}
            message={'Are you want to delete?'}
            onCancel={() => {
              SetToggleDeactivate(false)
            }}
            onDelete={deactive}
          />
        </DeleteContactPopup>
      )}
 {loading && (
          <PopUpStyleLoader>
            <LottieWrapper>
              <Lottie animationData={Loader} loop={true} />
            </LottieWrapper>
            <div style={{ marginLeft: '10px' }}>
              <Label> Loading...</Label>
            </div>
          </PopUpStyleLoader>
        )}

      {toastPopup && (
        <ToastWrapper>
          <Toast
            text={status}
            type={response ? 'success' : 'danger'}
            openStatusCallback={() => {
              setToastPopup(false)
            }}
          />
        </ToastWrapper>
      )}
    </EmailModalWrapper>
  )
}

export default AddContactMpid