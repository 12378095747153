/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, Dispatch, PayloadAction, Slice } from '@reduxjs/toolkit'
import { StoreState } from '../index'
import { authApiCall } from '../../utils/api'

interface State {
  tradeManagement: []
  isLoading: boolean
  action: string
  assetType: []
  age: []
  MPID: []
  Desk:[]
  status: string
  response: boolean
}

let initialState: State = {
  tradeManagement: [],
  isLoading: true,
  action: '',
  assetType: [],
  age: [],
  MPID: [],
  Desk:[],
  status: '',
  response: false,
}

const { actions, reducer }: Slice = createSlice({
  name: 'trade-break',
  initialState,
  reducers: {
    SetTradeManagement: (state: State, action: PayloadAction<any>) => {
      state.tradeManagement = action.payload.data
    },
    SetAssetType: (state: State, action: PayloadAction<any>) => {
      state.assetType = action.payload.data
    },
    SetAge: (state: State, action: PayloadAction<any>) => {
      state.age = action.payload.data
    },
    setMPID: (state: State, action: PayloadAction<[]>) => {
      state.MPID = action.payload
    },
    setDesk: (state: State, action: PayloadAction<[]>) => {
      state.Desk = action.payload
    },
    setIsLoading: (state: State, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setResponse: (state: State, action: PayloadAction<boolean>) => {
      state.response = action.payload
    },
    setStatus: (state: State, action: PayloadAction<string>) => {
      state.status = action.payload
    },
    setAction: (state: State, action: PayloadAction<string>) => {
      state.action = action.payload
    },
  },
})

export const {
  setAction,
  SetTradeManagement,
  setIsLoading,
  SetAssetType,
  SetAge,
  setResponse,
  setStatus,
  setMPID,
  setDesk
} = actions

export const getAssetType: any = () => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.get(
      'tradebreakapi/TradeBreak/GetAssetType'
    )

    if (status === 200) {
      //dispatch(setAction(action))
      dispatch(
        SetAssetType({
          data: data.assetTypes,
        })
      )
      dispatch(setIsLoading(false))
      return data
    }
  } catch (e: any) {
    dispatch(setIsLoading(false))
    dispatch(
      SetAge({
        data: '',
      })
    )
  }
}
export const getTradeBreak: any =
  (
    action: string,
    fromDate: string,
    toDate: string,
    settleDate: any,
    assetType: any,
    entitycode: any,
    cusip: any,
    symbol: any,
    mpid: any,
    forDate: any,
    desk: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/TradeBreak/GetTradeBreakByMPID',
        {
          fromDate,
          toDate,
          settleDate,
          assetType,
          entitycode,
          cusip,
          symbol,
          mpid,
          forDate,
          desk,
        }
      )

      if (status === 200) {
        await dispatch(
          SetTradeManagement({
            data: data,
          })
        )
        await dispatch(setAction(action))
        dispatch(setIsLoading(false))
        return data
      }
    } catch (e: any) {
      dispatch(
        SetTradeManagement({
          data: [],
        })
      )
      dispatch(setIsLoading(false))
    }
  }

export const gettradeBreakEXCEL880: any =
  (
    filename: string,
    fromDate: string,
    toDate: string,
    settleDate: string,
    entitycode: string,
    cusip: string,
    symbol: string,
    assetType: string,
    mpid: string,
    desk: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { data, status } = await authApiCall.post(
        'tradebreakapi/TradeBreak/ExportToExcelRPT880',
        {
          fromDate,
          toDate,
          settleDate,
          entitycode,
          cusip,
          symbol,
          assetType,
          mpid,
          desk,
        },
        { responseType: 'blob' }
      )

      if (status === 200) {
        const url = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${filename}.xlsx`)
        document.body.appendChild(link)
        link.click()
        return status
      }
      dispatch(setIsLoading(false))
    } catch (e: any) {
      dispatch(setIsLoading(false))
      return 400
    }
  }
export const GetContactMpid: any = () => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.get(
      'tradebreakapi/TradeBreak/GetTradeReconContact'
    )

    if (status === 200) {
      dispatch(setIsLoading(false))
      return data
    }
  } catch (e: any) {
    dispatch(setIsLoading(false))
    if (e.response.status === 401) return e.response.status
  }
}
export const addContactMpid: any =
  (
    id: number,
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    phone: string,
    notes: string,
    mPID: string,
    desk:string,
    activeYN: 'Y'
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.post(
        'tradebreakapi/TradeBreak/AddTradeReconContact',
        {
          id,
          firstName,
          lastName,
          company,
          email,
          phone,
          notes,
          mPID,
          desk,
          activeYN,
        }
      )
      if (status === 200) {
        dispatch(setStatus('Added Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
      }
    } catch (e: any) {
      dispatch(setStatus('Something Went Wrong'))
      dispatch(setIsLoading(false))
      dispatch(setResponse(false))
    }
  }

export const updateContactMpid: any =
  (
    id: number,
    firstName: string,
    lastName: string,
    company: string,
    email: string,
    phone: string,
    notes: string,
    mPID: string,
    desk: string,
    activeYN: string
  ) =>
  async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.put(
        'tradebreakapi/TradeBreak/UpdateTradeReconContact',
        {
          id,
          firstName,
          lastName,
          company,
          email,
          phone,
          notes,
          mPID,
          desk,
          activeYN,
        }
      )
      if (status === 200) {
        dispatch(setStatus('Updated Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
      }
    } catch (e: any) {
      dispatch(setStatus('Something Went Wrong'))
      dispatch(setIsLoading(false))
      dispatch(setResponse(false))
    }
  }
export const deleteContactMpid: any =
  (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(true))
    try {
      const { status } = await authApiCall.delete(
        `tradebreakapi/TradeBreak/DeleteTradeReconContact?Id=${id}`
      )
      if (status === 200) {
        dispatch(setStatus('Deleted Successfully'))
        dispatch(setIsLoading(false))
        dispatch(setResponse(true))
      }
    } catch (e: any) {
      dispatch(setStatus('Something Went Wrong'))
      dispatch(setIsLoading(false))
      dispatch(setResponse(false))
    }
  }
export const GetMPIDList: any = () => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.get(
      `tradebreakapi/TradeBreak/GetMPIDList`
    )
    if (status === 200) {
      dispatch(setMPID(data.mpidList))
      dispatch(setIsLoading(false))
    }
  } catch (e: any) {
    dispatch(setIsLoading(false))
  }
}
export const GetDeskList: any = () => async (dispatch: Dispatch) => {
  dispatch(setIsLoading(true))
  try {
    const { data, status } = await authApiCall.post(
      `tradebreakapi/TradeBreak/GetDeskDetailsByMPID`,"WSGD"
    )
    if (status === 200) {
      dispatch(setDesk(data.deskbyMpidResponse))
      dispatch(setIsLoading(false))
    }
  } catch (e: any) {
    dispatch(setIsLoading(false))
  }
}
// export const ageSelector = (store: StoreState) =>
//   store.trade.TradeManagement.age;

export const assetSelector = (store: StoreState) =>
  store.trade.TradeManagement.assetType

export const tradeManageSelector = (store: StoreState) =>
  store.trade.TradeManagement.tradeManagement

export const MPIDSelector = (store: StoreState) =>
  store.trade.TradeManagement.MPID

  export const DeskSelector = (store: StoreState) =>
  store.trade.TradeManagement.Desk

export const actionSelector = (store: StoreState) =>
  store.trade.TradeManagement.action

export const loadingSelector = (store: StoreState) =>
  store.trade.TradeManagement.isLoading

export const statusSelector = (store: StoreState) =>
  store.trade.TradeManagement.status

export const ResponseSelector = (store: StoreState) =>
  store.trade.TradeManagement.response

export default reducer
